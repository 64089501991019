<style>
.event-input-label {
  display: inline-block;
  width: 240px;
}

.notice-style {
  background: #0065ac;
  color: #ffffff;
  font-size: 160%;
}
</style>


<template>
  <div class="app-container">
    <!-- Synchronize To CDN Status -->
    <template v-if="LeaderBoard_Apply_Settings_Status === APPLY_STATUS_ENABLE">
      <el-card class="box-card">
        <div>
          <el-button
            type="primary"
            size="small"
            @click="onApplyLeaderBoardSettings"
            >Apply LeaderBoard Settings</el-button
          >
        </div>
      </el-card>
      <br />
    </template>

    <template
      v-if="LeaderBoard_Apply_Settings_Status === APPLY_STATUS_PROCESSING"
    >
      <el-card class="box-card notice-style">
        <div>
          <span
            >Apply Settings Processing...{{
              LeaderBoard_Processing_Time_Inc
            }}s</span
          >
        </div>
      </el-card>
      <br />
    </template>

    <template v-if="LeaderBoard_Apply_Settings_Status === APPLY_STATUS_DONE">
      <el-card class="box-card notice-style">
        <div>
          <span>Application Of Settings Has Done.</span>
        </div>
      </el-card>
      <br />
    </template>
    <!-- End Synchronize To CDN Status -->

    <!-- Synchronize Reset Data Status -->
    <template v-if="Reset_All_Data_Status === APPLY_STATUS_PROCESSING">
      <el-card class="box-card notice-style">
        <div>
          <span
            >Reset All Data Processing...{{ Reset_All_Data_Time_Inc }}s</span
          >
        </div>
      </el-card>
      <br />
    </template>
    <!-- End Synchronize Reset Data Status -->

    <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="RaidBoss Settings" name="1">
        <el-tabs
          v-model="activeLeaderBoardTypes"
          @tab-click="handleClick"
          v-loading="loading"
        >
          <el-tab-pane label="RaidBoss" name="1_2">
            <el-table
              :data="RaidBossSettingsList"
              style="width: 100%"
              max-height="800"
              border
            >
              <el-table-column label="ID" width="50px">
                <template slot-scope="{ row }">
                  <span>{{ row.id }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Name" width="120px">
                <template slot-scope="{ row }">
                  <span>{{ row.data.name }}</span>
                </template>
              </el-table-column>

              <el-table-column label="EventID" width="120px">
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    <el-input
                      v-model="row.data.eventInfo.eventID"
                      class="edit-input"
                      size="small"
                    />
                  </template>
                  <span v-else>{{ row.data.eventInfo.eventID }}</span>
                </template>
              </el-table-column>

              <el-table-column label="EventName" width="120px">
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    <el-input
                      v-model="row.data.eventInfo.eventName"
                      class="edit-input"
                      size="small"
                    />
                  </template>
                  <span v-else>{{ row.data.eventInfo.eventName }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Date" width="220px">
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    From:
                    <el-date-picker
                      v-model="row.dataDisplay.eventInfo.startDate"
                      type="datetime"
                      placeholder="Select date and time"
                      format="dd/MM/yyyy HH:mm:ss"
                    >
                    </el-date-picker>
                    <br /><br />
                    To:
                    <el-date-picker
                      v-model="row.dataDisplay.eventInfo.endDate"
                      type="datetime"
                      placeholder="Select date and time"
                      format="dd/MM/yyyy HH:mm:ss"
                    >
                    </el-date-picker>
                  </template>
                  <span v-else
                    >From:
                    {{
                      row.dataDisplay.eventInfo.startDate.toLocaleString("en-GB", { hour12: false })
                    }}
                    <br /><br />
                    To:
                    {{
                      row.dataDisplay.eventInfo.endDate.toLocaleString("en-GB", { hour12: false })
                    }}</span
                  >
                  <br />
                </template>
              </el-table-column>

              <el-table-column label="Boss Active Time (Loop)" width="120px">
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    <el-input
                      v-model="row.data.bossActiveTime"
                      class="edit-input"
                      size="small"
                    />
                  </template>
                  <span v-else>{{ row.data.bossActiveTime }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Boss Live (Loop)" width="120px">
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    <el-input
                      v-model="row.data.bossLiveTime"
                      class="edit-input"
                      size="small"
                      @change="onBossLiveChange(row.id)"
                    />
                  </template>
                  <span v-else>{{ row.data.bossLiveTime }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Boss Wait End Time (Loop)" width="120px">
                <template slot-scope="{ row }">
                  <span>{{ row.data.bossWaitEndTime }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Enable Cheat" width="120px">
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    <el-input
                      v-model="row.data.isEnableRaidBossCheat"
                      class="edit-input"
                      size="small"
                    />
                  </template>
                  <span v-else>{{ row.data.isEnableRaidBossCheat }}</span>
                </template>
              </el-table-column>

              // Actions for rewards
              <el-table-column label="Actions" width="250px">
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    <template v-if="row.updateMode">
                      <el-button
                        type="success"
                        size="small"
                        icon="el-icon-edit-outline"
                        disabled
                        @click="confirmEdit(row)"
                        >Update</el-button
                      >
                      <br />
                      <el-button
                        class="cancel-btn"
                        size="small"
                        icon="el-icon-refresh"
                        type="warning"
                        disabled
                        @click="cancelEdit(row)"
                        >Cancel</el-button
                      >
                    </template>
                    <template v-else>
                      <el-button
                        type="success"
                        size="small"
                        icon="el-icon-edit-outline"
                        @click="confirmEdit(row)"
                        >Update</el-button
                      >
                      <br />
                      <br />
                      <el-button
                        class="cancel-btn"
                        size="small"
                        icon="el-icon-refresh"
                        type="warning"
                        @click="cancelEdit(row)"
                        >Cancel</el-button
                      >
                    </template>
                  </template>
                  <template v-else>
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-edit"
                      @click="row.editMode = true"
                      >Edit</el-button
                    >
                    <br />
                    <br />
                    <template>
                      <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-edit"
                        @click="
                          onSyncEventTimeToBossTime(
                            row.data.name,
                            row.data.eventInfo.eventID
                          )
                        "
                        >Sync Event Time To Boss Time</el-button
                      >
                    </template>
                    <br />
                    <br />
                    <el-button
                      type="danger"
                      size="small"
                      icon="el-icon-delete"
                      @click="onDelete(row)"
                      >Delete</el-button
                    >
                    <br />
                    <br />
                  </template>

                  <template v-if="isDebugMode">
                    <el-button
                      type="danger"
                      size="small"
                      icon="el-icon-delete"
                      @click="onResetAll(row.data.name)"
                      >Reset All Data</el-button
                    >
                  </template>
                </template>
              </el-table-column>

              <el-table-column label="Reward Lists" width="1050px">
                <template slot-scope="{ row }">
                  <el-button
                    type="success"
                    size="small"
                    v-bind:icon="row.ButtonIconName_CollapseRewardList"
                    @click="OnMainCollapseReward(row)"
                    style="width: 40%"
                    >{{ row.ButtonTitle_CollapseRewardList }}
                  </el-button>
                  <br />
                  <br />

                  <template v-if="!row.IsCollapseRewardList">
                    <template v-if="row.editMode">
                      <reward-list-with-name
                        v-bind:propData="row.data.rewardList"
                        v-bind:propEditMode="row.editMode"
                        v-on:CallbackChangeData="CallbackChangeGroupData"
                        v-on:CallbackChangeRewardList="
                          Settings_CallbackChangeBossReward
                        "
                      >
                      </reward-list-with-name>
                    </template>
                    <span v-else>
                      <reward-list-with-name
                        v-bind:propData="row.data.rewardList"
                        v-bind:propEditMode="row.editMode"
                        v-on:CallbackChangeData="CallbackChangeGroupData"
                        v-on:CallbackChangeRewardList="
                          Settings_CallbackChangeBossReward
                        "
                      >
                      </reward-list-with-name>
                    </span>
                  </template>
                  <br />

                  <template v-if="!row.IsCollapseRewardList">
                    <el-button
                      type="success"
                      size="small"
                      icon="el-icon-arrow-up"
                      @click="OnMainCollapseReward(row)"
                      style="width: 40%"
                      >{{ row.ButtonTitle_CollapseRewardList }}
                    </el-button>
                  </template>
                </template>
              </el-table-column>

              // Actions for rewards
              <el-table-column label="Actions" width="100px">
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    <template v-if="row.updateMode">
                      <el-button
                        type="success"
                        size="small"
                        icon="el-icon-edit-outline"
                        disabled
                        @click="confirmEdit(row)"
                        >Update</el-button
                      >
                      <br />
                      <el-button
                        class="cancel-btn"
                        size="small"
                        icon="el-icon-refresh"
                        type="warning"
                        disabled
                        @click="cancelEdit(row)"
                        >Cancel</el-button
                      >
                    </template>
                    <template v-else>
                      <el-button
                        type="success"
                        size="small"
                        icon="el-icon-edit-outline"
                        @click="confirmEdit(row)"
                        >Update</el-button
                      >
                      <br />
                      <br />
                      <el-button
                        class="cancel-btn"
                        size="small"
                        icon="el-icon-refresh"
                        type="warning"
                        @click="cancelEdit(row)"
                        >Cancel</el-button
                      >
                    </template>
                  </template>
                  <template v-else>
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-edit"
                      @click="row.editMode = true"
                      >Edit</el-button
                    >
                    <br />
                    <br />
                    <el-button
                      type="danger"
                      size="small"
                      icon="el-icon-delete"
                      @click="onDelete(row)"
                      >Delete</el-button
                    >
                    <br />
                    <br />
                  </template>
                </template>
              </el-table-column>

              <el-table-column
                label="User Groups and Final Rewards"
                width="950px"
              >
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    <leader-board-group-with-reward-list
                      v-bind:propID="row.id"
                      v-bind:propData="row.data.grandBoss.groupReward"
                      v-bind:propRewardList="row.data.rewardList"
                      v-bind:propEditMode="row.editMode"
                      v-on:CallbackChangeData="CallbackChangeGroupData"
                    ></leader-board-group-with-reward-list>
                  </template>
                  <span v-else>
                    <leader-board-group-with-reward-list
                      v-bind:propID="row.id"
                      v-bind:propData="row.data.grandBoss.groupReward"
                      v-bind:propRewardList="row.data.rewardList"
                      v-bind:propEditMode="row.editMode"
                      v-on:CallbackChangeData="CallbackChangeGroupData"
                    ></leader-board-group-with-reward-list>
                  </span>
                </template>
              </el-table-column>

              // Actions for rewards
              <el-table-column label="Actions" width="100px">
                <template slot-scope="{ row }">
                  <template v-if="row.editMode">
                    <template v-if="row.updateMode">
                      <el-button
                        type="success"
                        size="small"
                        icon="el-icon-edit-outline"
                        disabled
                        @click="confirmEdit(row)"
                        >Update</el-button
                      >
                      <br />
                      <el-button
                        class="cancel-btn"
                        size="small"
                        icon="el-icon-refresh"
                        type="warning"
                        disabled
                        @click="cancelEdit(row)"
                        >Cancel</el-button
                      >
                    </template>
                    <template v-else>
                      <el-button
                        type="success"
                        size="small"
                        icon="el-icon-edit-outline"
                        @click="confirmEdit(row)"
                        >Update</el-button
                      >
                      <br />
                      <br />
                      <el-button
                        class="cancel-btn"
                        size="small"
                        icon="el-icon-refresh"
                        type="warning"
                        @click="cancelEdit(row)"
                        >Cancel</el-button
                      >
                    </template>
                  </template>
                  <template v-else>
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-edit"
                      @click="row.editMode = true"
                      >Edit</el-button
                    >
                    <br />
                    <br />
                    <el-button
                      type="danger"
                      size="small"
                      icon="el-icon-delete"
                      @click="onDelete(row)"
                      >Delete</el-button
                    >
                    <br />
                    <br />
                  </template>
                </template>
              </el-table-column>

              <el-table-column label="Little Bosses" width="1650px">
                <template slot-scope="{ row }">
                  <el-button
                    type="success"
                    size="small"
                    v-bind:icon="row.ButtonIconName_CollapseLittleBossList"
                    @click="OnMainCollapseLittleBoss(row)"
                    style="width: 40%"
                    >{{ row.ButtonTitle_CollapseLittleBossList }}
                  </el-button>
                  <br />
                  <br />

                  <template v-if="!row.IsCollapseLittleBossList">
                    <template v-if="row.editMode">
                      <little-boss-list
                        v-bind:propID="row.id"
                        v-bind:propData="row.dataDisplay.littleBoss.bossList"
                        v-bind:propRewardList="row.data.rewardList"
                        v-bind:propEditMode="row.editMode"
                        v-on:CallbackChangeData="CallbackChangeGroupData"
                      ></little-boss-list>
                    </template>
                    <span v-else>
                      <little-boss-list
                        v-bind:propID="row.id"
                        v-bind:propData="row.dataDisplay.littleBoss.bossList"
                        v-bind:propRewardList="row.data.rewardList"
                        v-bind:propEditMode="row.editMode"
                        v-on:CallbackChangeData="CallbackChangeGroupData"
                      ></little-boss-list>
                    </span>
                  </template>
                  <br />

                  <template v-if="!row.IsCollapseLittleBossList">
                    <el-button
                      type="success"
                      size="small"
                      icon="el-icon-arrow-up"
                      @click="OnMainCollapseLittleBoss(row)"
                      style="width: 40%"
                      >{{ row.ButtonTitle_CollapseLittleBossList }}
                    </el-button>
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>

      <el-tab-pane label="RaidBoss Add" name="2">
        <el-form
          ref="form"
          :model="RaidbossSettingdAddItem"
          label-width="120px"
        >
          <el-form-item label="Name" style="width: 40%">
            <el-input
              v-model="RaidbossSettingdAddItem.name"
              class="edit-input"
              placeholder="Boss Name. Example: grandboss"
            />
          </el-form-item>

          <el-form-item>
            <el-card class="box-card" style="min-height: 150px">
              <div slot="header" class="clearfix">
                <span>Events</span>
              </div>

              <el-form-item label="Auto Fill Event (RaidBoss)">
                <el-select
                  v-model="EventAutoFill.LeaderBoardItem_EventID_AutoFill"
                  value-key="LeaderBoardItem_EventID_AutoFill"
                  filterable
                  allow-create
                  placeholder="Select"
                  @change="AddItem_onEventIDChange($event)"
                  style="width: 16%"
                >
                  <el-option
                    v-for="item in LeaderBoardItem_EventIDList"
                    :key="item.eventID"
                    :label="item.eventID"
                    :value="item.eventID"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <br />

              <el-form-item label="Event Type">
                <el-input
                  v-model="EventAutoFill.LeaderBoardItem_EventType"
                  class="edit-input"
                  placeholder="Event Type. Example: RadBoss"
                  @change="AddItem_onEventTypeChange($event)"
                  style="width: 16%"
                  :readonly="true"
                />
              </el-form-item>
              <br />

              <el-form-item label="Event ID" style="width: 40%">
                <el-input
                  v-model="EventAutoFill.LeaderBoardItem_EventID"
                  class="edit-input"
                  placeholder="Event ID. Example: 202101"
                  @change="AddItem_OnChangeEventID($event)"
                />
              </el-form-item>
              <br />

              <el-form-item label="EventName" style="width: 40%">
                <el-input
                  v-model="EventAutoFill.LeaderBoardItem_EventName"
                  class="edit-input"
                  placeholder="Event Name. Example: Event XMas"
                  @change="AddItem_OnChangeEventName($event)"
                />
              </el-form-item>
              <br />

              <el-form-item label="Begin Time">
                <el-date-picker
                  v-model="EventAutoFill.LeaderBoardItem_EventDate_Begin"
                  type="datetime"
                  placeholder="Select date and time"
                  @change="AddItem_OnChangeGroupBeginDate($event)"
                >
                </el-date-picker>
              </el-form-item>
              <br />

              <el-form-item label="End Time">
                <el-date-picker
                  v-model="EventAutoFill.LeaderBoardItem_EventDate_End"
                  type="datetime"
                  placeholder="Select date and time"
                  @change="AddItem_OnChangeGroupEndDate($event)"
                >
                </el-date-picker>
              </el-form-item>
              <br />
            </el-card>
          </el-form-item>

          <el-form-item>
            <el-card class="box-card" style="min-height: 150px">
              <div slot="header" class="clearfix">
                <span>Reward Lists</span>
              </div>

              <!-- Handle Import Reward from a file -->
              <el-form-item label="Reward Files" style="width: 50%">
                <el-upload
                  class="upload-demo"
                  drag
                  action=""
                  ref="upload2"
                  :auto-upload="false"
                  :multiple="true"
                  :on-change="AddItem_handleChangeQuickRewards"
                  :on-remove="AddItem_handleRemoveQuickRewards"
                  :file-list="additem_uploadFileQuickRewards"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop rewards files here!</div>
                </el-upload>

                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="AddItem_handleQuickRewards()"
                  >Add Quick Rewards
                </el-button>
              </el-form-item>
              <br />

              <reward-list-with-name
                v-bind:propEditMode="LittleBossListAutoFill.editMode"
                v-bind:propData="RaidbossSettingdAddItem.rewardList"
                v-on:CallbackChangeRewardList="AddItem_OnListChangeBossReward"
              >
              </reward-list-with-name>

              <el-form-item style="width: 50%">
                <br />
                <el-upload
                  class="upload-demo"
                  action=""
                  ref="upload"
                  :auto-upload="false"
                  :multiple="false"
                  :limit="1"
                  :on-change="AddItem_onImportRewardList"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >Import</el-button
                  >

                  &emsp;&emsp;&emsp;&emsp;&emsp;

                  <el-button
                    type="info"
                    size="small"
                    icon="el-icon-download"
                    @click="AddItem_onExportRewardList()"
                    >Export</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-card>
          </el-form-item>

          <el-form-item>
            <el-card class="box-card" style="min-height: 150px">
              <div slot="header" class="clearfix">
                <span>User Group and Final Reward</span>
              </div>

              <el-form-item label="OS">
                <el-select
                  v-model="GroupAutoFill.LeaderBoardItem_Groups_OS"
                  value-key="LeaderBoardItem_Groups_OS"
                  filterable
                  allow-create
                  placeholder="Select"
                  @change="AddItem_onGroupOSChange($event)"
                  style="width: 20%"
                >
                  <el-option
                    v-for="item in LeaderBoardItem_Groups_OSList"
                    :key="item.osName"
                    :label="item.osName"
                    :value="item.osName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <br />

              <el-form-item label="Type">
                <el-select
                  v-model="GroupAutoFill.LeaderBoardItem_Groups_Type"
                  value-key="LeaderBoardItem_Groups_Type"
                  filterable
                  allow-create
                  placeholder="Select"
                  @change="AddItem_onGroupTypeChange($event)"
                  style="width: 20%"
                >
                  <el-option
                    v-for="item in LeaderBoardItem_Groups_TypeList"
                    :key="item.TypeName"
                    :label="item.TypeName"
                    :value="item.TypeName"
                  >
                  </el-option>
                </el-select>

                <template
                  v-if="
                    GroupAutoFill.LeaderBoardItem_Groups_Type.includes('us')
                  "
                >
                  <span>
                    <br />(all users in the tier1 countries: US, GB, FR, DE, IT,
                    AU, CA, KR, JP, TW, HK)
                  </span>
                </template>

                <template
                  v-if="
                    GroupAutoFill.LeaderBoardItem_Groups_Type.includes('tier3')
                  "
                >
                  <span>
                    <br />(all users in the tier3 countries: IN, PH, ID)
                  </span>
                </template>
              </el-form-item>
              <br />

              <el-form-item label="Count" style="width: 20%">
                <el-input
                  v-model="GroupAutoFill.LeaderBoardItem_Groups_Count"
                  class="edit-input"
                  placeholder=""
                />
              </el-form-item>
              <br />

              <el-form-item label="Payment Size" style="width: 20%">
                <el-input
                  v-model="GroupAutoFill.LeaderBoardItem_Groups_Payment_Size"
                  class="edit-input"
                  placeholder="Payment Size <= Size"
                />
              </el-form-item>
              <br />

              <el-form-item
                label="None Payment (Fast Fill Board)"
                style="width: 20%"
              >
                <el-input
                  v-model="
                    GroupAutoFill.LeaderBoardItem_Groups_None_Payment_Limit_Size_1
                  "
                  class="edit-input"
                  placeholder="None Payment Limit Size <= Size"
                />
              </el-form-item>
              <br />

              <el-form-item label="Total Size" style="width: 20%">
                <el-input
                  v-model="GroupAutoFill.LeaderBoardItem_Groups_Size"
                  class="edit-input"
                  placeholder=""
                />
              </el-form-item>
              <br />

              <el-form-item label="Final Reward" style="width: 100%">
                <el-select
                  v-model="GroupAutoFill.LeaderBoardItem_Groups_RewardName"
                  value-key="rewardName"
                  filterable
                  allow-create
                  placeholder="Select"
                  style="width: 16%"
                >
                  <el-option
                    v-for="item in RaidbossSettingdAddItem.rewardList"
                    :key="item.rewardName"
                    :label="item.rewardName"
                    :value="item.rewardName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <br />

              <el-form-item>
                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="AddItem_OnLeaderBoardItem_Groups_Add()"
                  >Add Group</el-button
                >

                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="AddItem_OnLeaderBoardItem_Groups_Delete()"
                  >Delete Group</el-button
                >
              </el-form-item>
              <br />

              <el-card class="box-card" style="min-height: 100px">
                <div slot="header" class="clearfix">
                  <span> Groups Review</span>
                </div>

                <leader-board-group-with-reward-list
                  v-bind:propData="
                    RaidbossSettingdAddItem.grandBoss.groupReward
                  "
                  v-bind:propEditMode="false"
                  v-on:CallbackChangeData="CallbackChangeGroupData"
                ></leader-board-group-with-reward-list>
              </el-card>
            </el-card>
          </el-form-item>

          <el-form-item>
            <el-card class="box-card" style="min-height: 150px">
              <div slot="header" class="clearfix">
                <span>Bosses and Rewards</span>
              </div>

              <el-form-item label="Boss Caption" style="width: 40%">
                <el-input
                  v-model="LittleBossAutoFill.bossCaption"
                  class="edit-input"
                  placeholder=""
                />
              </el-form-item>
              <br />

              <el-form-item label="Count" style="width: 20%">
                <el-input
                  v-model="LittleBossAutoFill.count"
                  class="edit-input"
                  placeholder=""
                />
              </el-form-item>
              <br />

              <el-form-item label="Active Time Daily" style="width: 20%">
                <el-input
                  v-model="LittleBossAutoFill.activeIntervalInSec"
                  class="edit-input"
                  placeholder=""
                />
              </el-form-item>
              <br />

              <el-form-item label="Boss Active" style="width: 20%">
                <el-input
                  v-model="LittleBossAutoFill.activeIntervalBossInSec"
                  class="edit-input"
                  @change="AddItem_onActiveIntervalBossInSecChange()"
                  placeholder=""
                />
              </el-form-item>
              <br />

              <el-form-item label="Waiting Time Next Boss" style="width: 20%">
                <el-input
                  v-model="LittleBossAutoFill.waitNextBossIntervalInSec"
                  class="edit-input"
                  placeholder=""
                  :readonly="true"
                />
              </el-form-item>
              <br />

              <el-form-item style="width: 50%">
                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="AddItem_OnGenerateEmptyListLittleBoss()"
                  >Generate Default Boss List</el-button
                >

                <el-upload
                  class="upload-demo"
                  action=""
                  ref="upload"
                  :auto-upload="false"
                  :multiple="false"
                  :limit="1"
                  :on-change="AddItem_onSyncBossListValuesFromConfigFile"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >Load BossID, HP values By Segment in ConfigRaiBoss
                    File...</el-button
                  >
                </el-upload>

                <el-upload
                  class="upload-demo"
                  action=""
                  ref="upload"
                  :auto-upload="false"
                  :multiple="false"
                  :limit="1"
                  :on-change="AddItem_onSyncBossListRewardFromConfigFile"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >Sync Reward Name...</el-button
                  >
                </el-upload>
              </el-form-item>

              <little-boss-list
                v-bind:propData="LittleBossListAutoFill.bossList"
                v-bind:propRewardList="RaidbossSettingdAddItem.rewardList"
                v-bind:propEditMode="LittleBossListAutoFill.editMode"
                v-on:CallbackChangeData="CallbackChangeGroupData"
              ></little-boss-list>
            </el-card>

            <el-form-item>
              <el-button
                type="success"
                size="small"
                icon="el-icon-edit-outline"
                @click="AddItem_OnAddBossSettings()"
                >Add Boss Settings</el-button
              >
            </el-form-item>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="RaidBoss Status" name="3">
        <el-form ref="form" label-width="120px">
          <el-form-item>
            <el-card class="box-card" style="min-height: 150px; width: 50%">
              <div slot="header" class="clearfix">
                <span>Select Boss Current Group</span>
              </div>

              <el-form-item label="Boss Name">
                <!-- Select application -->
                <el-select
                  v-model="SelectedBossName"
                  value-key="SelectedBossName"
                  filterable
                  allow-create
                  placeholder="Select"
                  @change="onSelectedBossNameChange($event)"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in listBossInfos"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <br />
            </el-card>
          </el-form-item>

          <el-form-item>
            <el-card class="box-card" style="min-height: 150px; width: 100%">
              <div slot="header" class="clearfix">
                <span>All Current Boss Groups</span>
              </div>

              <el-button
                type="success"
                size="small"
                icon="el-icon-edit-outline"
                @click="Statistic_OnRefreshAllBossGroups()"
                >Refresh</el-button
              >

              <br />
              <br />

              <el-table
                :data="RaidBossAllGroupList"
                style="width: 100%"
                max-height="800"
                border
              >
                <el-table-column label="ID" width="50px">
                  <template slot-scope="{ row }">
                    <span>{{ row.idx }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Boss Group" width="300px">
                  <template slot-scope="{ row }">
                    <span>{{ row.bossGroupName }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Boss ID" width="80px">
                  <template slot-scope="{ row }">
                    <span>{{ row.bossID }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="HP" width="100px">
                  <template slot-scope="{ row }">
                    <span>{{ row.hp }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Max HP" width="100px">
                  <template slot-scope="{ row }">
                    <span>{{ row.maxHP }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Eliminate Time" width="200px">
                  <template slot-scope="{ row }">
                    <span>{{ row.eliminateBossTimeDisplay }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Eliminated By" width="200px">
                  <template slot-scope="{ row }">
                    <span>{{ row.eliminateBossBy }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Last Battle HP" width="120px">
                  <template slot-scope="{ row }">
                    <span>{{ row.eliminateBossLastBattleHP }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Gift Status" width="220px">
                  <template slot-scope="{ row }">
                    <span>{{ row.giftStatus }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-form-item>

          <el-form-item>
            <el-card class="box-card" style="min-height: 150px; width: 50%">
              <div slot="header" class="clearfix">
                <span>Reset Boss Current Group</span>
              </div>

              <el-form-item label="Group Name:" style="width: 100%">
                <el-select
                  v-model="ResetBoss_GroupName"
                  value-key="ResetBoss_GroupName"
                  filterable
                  allow-create
                  placeholder="Select"
                  @change="ResetBoss_onGroupChange($event)"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in Reset_listBossGroupInfos"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <br />

              <el-card class="box-card" style="min-height: 128px">
                <div slot="header" class="clearfix">
                  <span>Get Boss Group Status</span>
                </div>

                <el-form-item label="Information">
                  <el-input
                    v-model="ResetBoss_GroupInfo"
                    placeholder="Information"
                    width="50px"
                    type="textarea"
                    rows="5"
                  ></el-input>

                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    @click="ResetBoss_OnGetStatus()"
                    >Read</el-button
                  >
                </el-form-item>
              </el-card>
              <br />

              <el-card class="box-card" style="min-height: 128px">
                <div slot="header" class="clearfix">
                  <span>Reset Boss Status</span>
                </div>

                <el-form-item label="HP:" style="width: 50%">
                  <el-input
                    v-model="ResetBoss_HP"
                    class="edit-input"
                    placeholder=""
                  />
                  (0: FULL HP)
                  <br />
                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    @click="ResetBoss_OnResetBoss()"
                    >Reset</el-button
                  >
                </el-form-item>
              </el-card>
            </el-card>
          </el-form-item>

          <el-form-item>
            <el-card class="box-card" style="min-height: 150px; width: 50%">
              <div slot="header" class="clearfix">
                <span>Boss Current Statistic</span>
              </div>

              <el-button
                type="success"
                size="small"
                icon="el-icon-edit-outline"
                @click="Statistic_OnRefreshDailyList()"
                >Refresh</el-button
              >

              <el-table
                :data="statisticFileData"
                style="width: 100%"
                max-height="990"
              >
                <el-table-column label=".No" width="50px">
                  <template slot-scope="{ row }">
                    <span>{{ row.id }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="File Name" width="400px">
                  <template slot-scope="{ row }">
                    <span>{{ row.fileName }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Links">
                  <template slot-scope="{ row }">
                    (<a v-bind:href="row.link">Link</a>)
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-form-item>

          <el-form-item>
            <el-card class="box-card" style="min-height: 150px; width: 50%">
              <div slot="header" class="clearfix">
                <span>Boss Cheat Log</span>
              </div>

              <el-button
                type="success"
                size="small"
                icon="el-icon-edit-outline"
                @click="Statistic_OnRefreshCheatLog()"
                >Refresh</el-button
              >

              <el-table
                :data="cheatLogFileData"
                style="width: 100%"
                max-height="990"
              >
                <el-table-column label=".No" width="50px">
                  <template slot-scope="{ row }">
                    <span>{{ row.id }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="File Name" width="400px">
                  <template slot-scope="{ row }">
                    <span>{{ row.fileName }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Links">
                  <template slot-scope="{ row }">
                    (<a v-bind:href="row.link">Link</a>)
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
const Json2CSVConverter = require("json-2-csv");

import { MessageBox, Message } from "element-ui";
import { Loading } from "element-ui";
import { isStringEmpty } from "@/utils/validate";
import { getToken, getOSToken } from "@/utils/auth";

import LeaderBoardGroupWithRewardList from "@/components/CompLeaderBoardGroupWithRewardList";
import LittleBossList from "@/components/CompLittleBossList";
import RewardListWithName from "@/components/CompRewardListWithName";

import {
  getCheatTime,
  setCheatTime,
  getBlackList,
  setBlackListUser,
  getApplicationInfos,
  UploadFileToRemoteCDN,
  UploadFileStatus,
  HelperGetDownloadLinks,
  HelperRestoreDataFromBackup,
  HelperGetEventData,
  HelperGetEventDataList,
  HelperSetEventData,
  HelperGetLeaderBoardAllSettings,
  HelperGetRaidBossAllSettings,
  HelperResetAllRaidBossData,
  HelperRaidBoss_ResetBossGroup,
  HelperRaidBoss_GetStatusBossGroup,
  HelperRaidBoss_GetStatusAllBossGroup,
  HelperRaidBoss_GetDailyStatisticFileList,
  HelperRaidBoss_GetCheatLogFileList,
  HelperGetAllLeaderBoardInfo,
  HelperSetLeaderBoardAllSettings,
  HelperSetRaidBossAllSettings,
  HelperApplyLeaderBoardAllSettings,
  HelperApplyLeaderBoardAllSettingsPolling,
  HelperApplyRaidBossAllSettingsPolling,
  HelperGetStatusPolling,
  TryParseUploadFile_ReadTextFile,
  ReadTextFile,
  HelperParseRewardFromFile,
  HelperParseRewardFromFileWithName,
  HelperParseJsonFromConfigFile,
  HelperTryAddTestUsers,
  HelperTryAddTestUserScores,
  HelperApplyEventTime,
  HelperGetTopUser,
  HelperGetUserData,
  HelperChangeUserGroup,
  HelperGetTestingUserList,
  DownloadJS,
} from "@/api/gameservice";

import { getEventDataFromFirebase } from "@/api/dynamic_events";

// Event Types
var EVENT_TYPE_LBQ = "LBQ";
var EVENT_TYPE_ENDLESS = "EndLess";
var EVENT_TYPE_RAIDBOSS = "RaidBoss";
var EVENT_TYPE_UNKNOWN = "Unknown";

// Status for Apply Settings
const APPLY_STATUS_READY = "READY";
const APPLY_STATUS_ENABLE = "ENABLE";
const APPLY_STATUS_PROCESSING = "PROCESSING";
const APPLY_STATUS_DONE = "DONE";

export default {
  name: "GameServiceRaidboss",
  components: {
    RewardListWithName,
    LeaderBoardGroupWithRewardList,
    LittleBossList,
  },
  data() {
    return {
      //------------------------------------
      activeNames: "1",
      activeLeaderBoardTypes: "1_2",
      //------------------------------------

      //------------------------------------
      LeaderBoard_Apply_Settings_Status: "",
      LeaderBoard_Processing_Time_Inc: 0,
      //------------------------------------
      // Apply Settings Statuses
      APPLY_STATUS_READY: APPLY_STATUS_READY,
      APPLY_STATUS_ENABLE: APPLY_STATUS_ENABLE,
      APPLY_STATUS_PROCESSING: APPLY_STATUS_PROCESSING,
      APPLY_STATUS_DONE: APPLY_STATUS_DONE,
      //----------------------------------
      loading: false,
      //------------------------------------

      //------------------------------------
      isDebugMode: false,
      //------------------------------------

      //------------------------------------
      Reset_All_Data_Status: "",
      Reset_All_Data_Time_Inc: 0,
      //------------------------------------

      // Event AutoFill
      EventAutoFill: {
        LeaderBoardItem_EventID_AutoFill: 0,
        LeaderBoardItem_EventID: 0,
        LeaderBoardItem_EventName: "",
        LeaderBoardItem_EventType: EVENT_TYPE_RAIDBOSS,
        LeaderBoardItem_EventDate_Begin: "",
        LeaderBoardItem_EventDate_End: "",
        LeaderBoardItem_EventDate_Lock_Before_End: 300,
      },

      LeaderBoardItem_EventIDList: [],
      LeaderBoardItem_EventTypeList: [
        { eventType: EVENT_TYPE_RAIDBOSS },
        { eventType: EVENT_TYPE_LBQ },
        { eventType: EVENT_TYPE_ENDLESS },
        { eventType: EVENT_TYPE_UNKNOWN },
      ],
      //------------------------------------
      RewardListAutoFill: {
        rewardName: "(none)",
        rewards: [],
      },
      //------------------------------------
      additem_uploadFileQuickRewards: [],
      //------------------------------------
      GroupAutoFill: {
        LeaderBoardItem_Groups_Name: "",
        LeaderBoardItem_Groups_OS: getOSToken().toLowerCase(), //"android",
        LeaderBoardItem_Groups_Type: "us",
        LeaderBoardItem_Groups_Count: 1,
        LeaderBoardItem_Groups_Size: 100,
        LeaderBoardItem_Groups_Payment_Size: 20,
        LeaderBoardItem_Groups_None_Payment_Limit_Size_1: 80,
        LeaderBoardItem_Groups_RewardName: "",
        //LeaderBoardItem_Groups_Rewards: [],
      },
      LeaderBoardItem_Groups_OSList: [{ osName: getOSToken().toLowerCase() }], //[{osName: "android"}],
      LeaderBoardItem_Groups_TypeList: [],

      LittleBossAutoFill: {
        bossCaption: "${RaidBoss<tier>}", //"GIANT DESTROYER TIER",
        count: 7,
        activeIntervalInSec: 86400,
        activeIntervalBossInSec: 79200,
        waitNextBossIntervalInSec: 7200,
      },

      LittleBossListAutoFill: {
        bossList: [],
        editMode: true,
      },
      //------------------------------------
      RaidBossSettingsList: [],

      // Raid Boss Setting Item is used for adding a new Boss Settings
      RaidbossSettingdAddItem: {
        name: "raidboss",
        grandBoss: {
          leaderBoardName: "",
          leaderBoardEventID: "",
          groupReward: [],
          //rewardName: "rewardName",
        },
        littleBoss: {
          leaderBoardName: "",
          leaderBoardEventID: "",
          bossList: [],
        },
        rewardList: [],
        eventInfo: {
          eventID: 0,
          eventName: "eventName",
          eventType: EVENT_TYPE_RAIDBOSS,
          startDate: 0,
          endDate: 0,
        },
      },
      //------------------------------------------------
      // The selected boss
      SelectedBossName: "",
      listBossInfos: [
        {
          value: "Boss1",
          label: "Boss1",
        },
        {
          value: "Boss2",
          label: "Boss2",
        },
      ],
      //------------------------------------------------
      // Reset Boss
      ResetBoss_HP: "0",
      ResetBoss_GroupName: "",
      ResetBoss_GroupInfo: "",
      Reset_listBossGroupInfos: [
        {
          value: "BossGroup1",
          label: "BossGroup1",
        },
        {
          value: "BossGroup2",
          label: "BossGroup2",
        },
      ],

      //------------------------------------------------
      RaidBossAllGroupList: [],
      //------------------------------------------------

      //------------------------------------------------
      // Statistic Boss
      // Example:
      //    [{id:1, fileName: 'abc', link: 'link'}]
      //
      statisticFileData: [],

      //------------------------------------------------
      cheatLogFileData: [],
      //------------------------------------------------
    };
  },
  created() {
    // get all settings
    this.onGetRaidBossAllSetings();
  },
  methods: {
    onGetRaidBossAllSetings() {
      // Reset variables
      //...
      this.LeaderBoardItem_EventIDList = [];

      // Load event settings from firebase
      //...
      this.getEventDataList();

      HelperGetRaidBossAllSettings()
        .then((response) => {
          // Groups:
          //[
          //  {
          //    name: "prefix1_1",
          //    count: 1,
          //    size: 2
          //  },...
          //]

          //"EventInfo": {
          //      "eventID": 201201,
          //      "startDate": 0,
          //      "endDate": 0
          // }

          //Message({
          //  message: "Get All Settings Data: " + JSON.stringify(response.data),
          //  type: "success",
          //  duration: 5 * 1000,
          //});

          if (response.data.code === 0) {
            Message({
              message: "Get All Settings Data SUCCESS: " + response.data.code,
              type: "success",
              duration: 5 * 1000,
            });
          } else {
            Message({
              message:
                "Get All Settings Data FAILED code: " + response.data.code,
              type: "error",
              duration: 5 * 1000,
            });
          }

          let allSettings = response.data.settings;

          this.LeaderBoard_Apply_Settings_Status = response.data.status;

          // start a timer to checking status processing next status ...
          if (
            this.LeaderBoard_Apply_Settings_Status === APPLY_STATUS_PROCESSING
          ) {
            this.LeaderBoard_Processing_Time_Inc = 0;
            this.loading = true;
            setTimeout(() => this.onRecheckApplySettingsStatus(), 5000);
          }

          //----------------------------------------------

          this.RaidBossSettingsList = [];

          this.listBossInfos = [];

          let startDate;
          let endDate;

          for (let i = 0; i < allSettings.length; i++) {
            let id = i + 1;

            startDate = new Date(allSettings[i].eventInfo.startDate * 1000);
            endDate = new Date(allSettings[i].eventInfo.endDate * 1000);

            let eventInfoDisplay = {
              eventID: allSettings[i].eventInfo.eventID,
              eventType: allSettings[i].eventInfo.eventType,
              eventName: allSettings[i].eventInfo.eventName,
              startDate: startDate,
              endDate: endDate,
            };

            //----------------------------------------------------
            // Revert Little Boss to Display list (convert timestamp to display date time)
            let littleBossList = [];

            for (
              let idx_lb = 0;
              idx_lb < allSettings[i].littleBoss.bossList.length;
              idx_lb++
            ) {
              let littleBossListItem =
                allSettings[i].littleBoss.bossList[idx_lb];

              startDate = new Date(littleBossListItem.startDate * 1000);
              endDate = new Date(littleBossListItem.endDate * 1000);

              littleBossList.push({
                name: littleBossListItem.name,
                startDate: startDate,
                endDate: endDate,
                info: littleBossListItem.info,
              });
            }

            let littleBossDisplay = {
              leaderBoardName: allSettings[i].littleBoss.leaderBoardName,
              leaderBoardEventID: allSettings[i].littleBoss.leaderBoardEventID,
              bossList: littleBossList,
            };
            //----------------------------------------------------

            this.RaidBossSettingsList.push({
              id: id,
              data: {
                name: allSettings[i].name,
                bossActiveTime: allSettings[i].bossActiveTime,
                bossLiveTime:
                  allSettings[i].bossActiveTime -
                  allSettings[i].bossWaitEndTime,
                bossWaitEndTime: allSettings[i].bossWaitEndTime,
                isEnableRaidBossCheat: allSettings[i].isEnableRaidBossCheat,
                grandBoss: allSettings[i].grandBoss,
                littleBoss: allSettings[i].littleBoss,
                rewardList: allSettings[i].rewardList,
                eventInfo: allSettings[i].eventInfo,
              },
              dataDisplay: {
                eventInfo: eventInfoDisplay,
                littleBoss: littleBossDisplay,
              },
              editMode: false,
              updateMode: false,
              IsCollapseLittleBossList: true,
              ButtonTitle_CollapseLittleBossList: "Click to Expand",
              ButtonIconName_CollapseLittleBossList: "el-icon-d-arrow-right",
              IsCollapseRewardList: true,
              ButtonTitle_CollapseRewardList: "Click to Expand",
              ButtonIconName_CollapseRewardList: "el-icon-d-arrow-right",
            });

            if (allSettings[i].isDebugMode && allSettings[i].isDebugMode > 0) {
              this.isDebugMode = true;
            }

            // update boss info list
            this.listBossInfos.push({
              value: allSettings[i].name,
              label: allSettings[i].name,
            });
          }

          // sort list of settings
          this.RaidBossSettingsList.sort(function (a, b) {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });

          //-----------------------
          if (this.listBossInfos.length > 0) {
            this.SelectedBossName =
              this.listBossInfos[this.listBossInfos.length - 1].value;

            this.onGetBossEventData();
          }
          //-----------------------

          //////////////////////////////////////////////////

          //For Add new leaderboard
          this.RaidbossSettingdAddItem = {
            name: "raidboss",
            grandBoss: {
              leaderBoardName: "",
              leaderBoardEventID: "",
              groupReward: [],
              //rewardName: "rewardName",
            },
            littleBoss: {
              leaderBoardName: "",
              leaderBoardEventID: "",
              bossList: [],
            },
            rewardList: [],
            eventInfo: {
              eventID: 0,
              eventName: "eventName",
              eventType: EVENT_TYPE_RAIDBOSS,
              startDate: 0,
              endDate: 0,
            },
          };
        })
        .catch((error) => {
          Message({
            message: "Get All Settings Data: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    getEventDataList() {
      getEventDataFromFirebase()
        .then((response) => {
          this.LeaderBoardItem_EventIDList = [];

          if (response.events) {
            /*Message({
              message:
                "Get getEventDataFromFirebase " +
                JSON.stringify(response.events),
              type: "success",
              duration: 5 * 1000,
            });*/

            // events load from firebase

            let eventTypeTmp = "";
            for (let idx = 0; idx < response.events.length; idx++) {
              if (response.events[idx].event_type === "EVENT_ENDLESS") {
                eventTypeTmp = EVENT_TYPE_ENDLESS;
              } else if (response.events[idx].event_type === "EVENT_LBQ") {
                eventTypeTmp = EVENT_TYPE_LBQ;
              } else {
                eventTypeTmp = EVENT_TYPE_UNKNOWN;
              }

              this.LeaderBoardItem_EventIDList.push({
                id: response.events[idx].id,
                eventID: response.events[idx].event_id,
                eventType: eventTypeTmp,
                eventName: response.events[idx].event_name,
                startDate: response.events[idx].date_begin._seconds,
                endDate: response.events[idx].date_end._seconds,
              });
            }

            // Fill first item as default
            if (this.LeaderBoardItem_EventIDList.length > 0) {
              this.EventAutoFill.LeaderBoardItem_EventID_AutoFill =
                this.LeaderBoardItem_EventIDList[0].eventID;

              this.EventAutoFill.LeaderBoardItem_EventID =
                this.LeaderBoardItem_EventIDList[0].eventID;

              this.EventAutoFill.LeaderBoardItem_EventName =
                this.LeaderBoardItem_EventIDList[0].eventName;

              // event load from firebase will be convert to type 'RaidBoss'
              this.EventAutoFill.LeaderBoardItem_EventType =
                EVENT_TYPE_RAIDBOSS;

              let _startDate = new Date(
                this.LeaderBoardItem_EventIDList[0].startDate * 1000
              );
              let _endDate = new Date(
                this.LeaderBoardItem_EventIDList[0].endDate * 1000
              );

              this.EventAutoFill.LeaderBoardItem_EventDate_Begin = _startDate;
              this.EventAutoFill.LeaderBoardItem_EventDate_End = _endDate;

              // Set eventInfo to adding item
              this.RaidbossSettingdAddItem.eventInfo = {
                eventID: this.LeaderBoardItem_EventIDList[0].eventID,
                eventName: this.LeaderBoardItem_EventIDList[0].eventName,
                eventType: this.EventAutoFill.LeaderBoardItem_EventType,
                startDate: this.LeaderBoardItem_EventIDList[0].startDate,
                endDate: this.LeaderBoardItem_EventIDList[0].endDate,
              };

              this.setDefaultGroupsByEventType();
            }
          }

          //Message({message: "Get getEventDataFromFirebase -> LeaderBoardItem_EventIDList " + JSON.stringify(this.LeaderBoardItem_EventIDList), type: "success", duration: 5 * 1000});
        })
        .catch((error) => {
          Message({
            message: "Can't fetch config data list: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    setDefaultGroupsByEventType() {
      //[{eventType: 'LBQ'}, {eventType: 'EndLess'}],
      if (
        this.EventAutoFill.LeaderBoardItem_EventType === EVENT_TYPE_RAIDBOSS
      ) {
        this.LeaderBoardItem_Groups_TypeList = [
          { TypeName: "us_region_lowerequal_4" },
          { TypeName: "us_region_greater_4" },
          { TypeName: "tier3_region_lowerequal_4" },
          { TypeName: "tier3_region_greater_4" },
          { TypeName: "others_region_lowerequal_4" },
          { TypeName: "others_region_greater_4" },
          { TypeName: "cheaters" },
          { TypeName: "testers" },
        ];

        this.GroupAutoFill.LeaderBoardItem_Groups_Type =
          this.LeaderBoardItem_Groups_TypeList[0].TypeName;
      } else {
        this.LeaderBoardItem_Groups_TypeList = [
          { TypeName: "us" },
          { TypeName: "tier3" },
          { TypeName: "others" },
          { TypeName: "cheaters" },
          { TypeName: "testers" },
        ];

        this.GroupAutoFill.LeaderBoardItem_Groups_Type =
          this.LeaderBoardItem_Groups_TypeList[0].TypeName;
      }
    },
    AddItem_onEventTypeChange(event) {
      Message({
        message:
          "Select event ID: " +
          event +
          ", event list length: " +
          this.LeaderBoardItem_EventTypeList.length,
        type: "success",
        duration: 5 * 1000,
      });

      this.EventAutoFill.LeaderBoardItem_EventType = event;
      this.EventAutoFill.LeaderBoardItem_EventID = "";
      this.EventAutoFill.LeaderBoardItem_EventName = "";
      this.RaidbossSettingdAddItem.eventInfo.eventType =
        this.EventAutoFill.LeaderBoardItem_EventType;

      this.setDefaultGroupsByEventType();
    },
    AddItem_OnChangeEventID(event) {
      //Message({message: "Select begin date: " + this.LeaderBoardItem_EventDate_Begin, type: "success", duration: 5 * 1000});

      this.RaidbossSettingdAddItem.eventInfo.eventID =
        this.EventAutoFill.LeaderBoardItem_EventID;

      this.setDefaultGroupsByEventType();
    },
    AddItem_OnChangeEventName(event) {
      //Message({message: "Select begin date: " + this.LeaderBoardItem_EventDate_Begin, type: "success", duration: 5 * 1000});

      this.RaidbossSettingdAddItem.eventInfo.eventName =
        this.EventAutoFill.LeaderBoardItem_EventName;
    },
    AddItem_OnChangeGroupBeginDate(event) {
      Message({
        message:
          "Select begin date: " +
          this.EventAutoFill.LeaderBoardItem_EventDate_Begin,
        type: "success",
        duration: 5 * 1000,
      });

      let date = new Date(this.EventAutoFill.LeaderBoardItem_EventDate_Begin);
      let timestamp = date.getTime() / 1000;

      this.RaidbossSettingdAddItem.eventInfo.startDate = timestamp;
    },
    AddItem_OnChangeGroupEndDate(event) {
      Message({
        message:
          "Select end date: " +
          this.EventAutoFill.LeaderBoardItem_EventDate_End,
        type: "success",
        duration: 5 * 1000,
      });

      let date = new Date(this.EventAutoFill.LeaderBoardItem_EventDate_End);
      let timestamp = date.getTime() / 1000;

      this.RaidbossSettingdAddItem.eventInfo.endDate = timestamp;
    },
    AddItem_onActiveIntervalBossInSecChange() {
      this.LittleBossAutoFill.waitNextBossIntervalInSec =
        Number(this.LittleBossAutoFill.activeIntervalInSec) -
        Number(this.LittleBossAutoFill.activeIntervalBossInSec);
    },
    AddItem_onEventIDChange(event) {

      let isExist = false;
      this.RaidBossSettingsList.forEach((configLDBSettings) => {
        let eventInfo = configLDBSettings.data.eventInfo;
        if (eventInfo.eventID === event) {
          Message({
            message: `LDB of Event ID: ${event} is existed, eventName = ${eventInfo.eventName}`,
            type: "error",
            duration: 5 * 1000,
          });
          isExist = true;
          return;
        }
      });

      if (isExist) {
        return false;
      }

      Message({
        message:
          "Select event ID: " +
          event +
          ", event list length: " +
          this.LeaderBoardItem_EventIDList.length,
        type: "success",
        duration: 5 * 1000,
      });

      for (let i = 0; i < this.LeaderBoardItem_EventIDList.length; i++) {
        if (this.LeaderBoardItem_EventIDList[i].eventID === event) {
          this.EventAutoFill.LeaderBoardItem_EventID = event;
          this.EventAutoFill.LeaderBoardItem_EventName =
            this.LeaderBoardItem_EventIDList[i].eventName;

          //this.EventAutoFill.LeaderBoardItem_EventType =
          //this.LeaderBoardItem_EventIDList[i].eventType;

          let _beginDate = new Date(
            this.LeaderBoardItem_EventIDList[i].startDate * 1000
          );
          let _endDate = new Date(
            this.LeaderBoardItem_EventIDList[i].endDate * 1000
          );

          this.EventAutoFill.LeaderBoardItem_EventDate_Begin = _beginDate;
          this.EventAutoFill.LeaderBoardItem_EventDate_End = _endDate;

          // set data to current leader board item
          this.RaidbossSettingdAddItem.eventInfo.eventID =
            this.EventAutoFill.LeaderBoardItem_EventID;

          this.RaidbossSettingdAddItem.eventInfo.eventName =
            this.EventAutoFill.LeaderBoardItem_EventName;

          this.RaidbossSettingdAddItem.eventInfo.eventType =
            this.EventAutoFill.LeaderBoardItem_EventType;

          this.RaidbossSettingdAddItem.eventInfo.startDate =
            this.LeaderBoardItem_EventIDList[i].startDate;

          this.RaidbossSettingdAddItem.eventInfo.endDate =
            this.LeaderBoardItem_EventIDList[i].endDate;

          this.setDefaultGroupsByEventType();

          break;
        }
      }
    },
    onBossLiveChange(id) {
      for (let i = 0; i < this.RaidBossSettingsList.length; i++) {
        this.RaidBossSettingsList[i].data.bossWaitEndTime =
          this.RaidBossSettingsList[i].data.bossActiveTime -
          this.RaidBossSettingsList[i].data.bossLiveTime;
      }
    },
    AddItem_onGroupOSChange(event) {
      Message({
        message:
          "Select Group OS: " +
          event +
          ", event list length: " +
          this.LeaderBoardItem_Groups_OSList.length,
        type: "success",
        duration: 5 * 1000,
      });

      for (let i = 0; i < this.LeaderBoardItem_Groups_OSList.length; i++) {
        if (this.LeaderBoardItem_Groups_OSList[i].osName === event) {
          this.GroupAutoFill.LeaderBoardItem_Groups_OS = event;
          break;
        }
      }
    },
    //------------------------------------------------------------------------------------
    // handle Remove and change Browse File
    AddItem_handleChangeQuickRewards(_file, fileList) {
      Message({
        message: "[AddItem_handleChangeQuickRewards] ...",
        type: "success",
        duration: 5 * 1000,
      });

      this.additem_uploadFileQuickRewards = fileList;
    },
    AddItem_handleQuickRewards() {
      let ThisObj = this;
      let ThisCount = 0;
      let ThisTotalFileCount = 0;

      this.RaidbossSettingdAddItem.rewardList = [];

      let input_files = [];
      this.additem_uploadFileQuickRewards.forEach((file) => {
        let fileName = "unknown";

        if (file.name) {
          fileName = file.name;
          // remove extension
          //
          let idx = fileName.indexOf(".");
          if (idx > 0) {
            fileName = fileName.substring(0, idx);
          }

          input_files.push({ name: fileName, dataRaw: file.raw });
        }
      });

      ThisTotalFileCount = input_files.length;

      //TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
      ReadTextFile(input_files, function (code, output_files) {
        if (code === 0) {
          //Message({
          //message: "[AddItem_handleChangeQuickRewards] " + JSON.stringify(output_files),
          //type: "success",
          //duration: 5 * 1000,
          //});

          let resultRewardListTemp = [];

          for (let i = 0; i < output_files.length; i++) {
            let outData = output_files[i].dataText;
            let outFileName = output_files[i].name;

            HelperParseRewardFromFileWithName(outFileName, outData)
              .then((response) => {
                if (response.data.code === 0) {
                  ThisCount++;

                  let rewardItem = {
                    rewardName: response.data.name, //fileName,
                    rewards: [],
                  };

                  for (let idx = 0; idx < response.data.rewards.length; idx++) {
                    let itemGift = JSON.parse(response.data.rewards[idx].gifts);

                    if (!itemGift) {
                      Message({
                        message:
                          "Invalid Gift content: " +
                          response.data.rewards[idx].gifts,
                        type: "error",
                        duration: 5 * 1000,
                      });
                      return;
                    }

                    rewardItem.rewards.push({
                      id: Number(response.data.rewards[idx].id),
                      title: response.data.rewards[idx].title,
                      rankMin: response.data.rewards[idx].rankMin,
                      rankMax: response.data.rewards[idx].rankMax,
                      requiredPoint: response.data.rewards[idx].requiredPoint,
                      gifts: response.data.rewards[idx].gifts,
                    });
                  }

                  resultRewardListTemp.push(rewardItem);

                  if (ThisTotalFileCount === ThisCount) {
                    //
                    // When the last item has done,
                    // fill reward items in order of input file list
                    // ...
                    for (
                      let idx_input = 0;
                      idx_input < input_files.length;
                      idx_input++
                    ) {
                      for (
                        let idx_result = 0;
                        idx_result < resultRewardListTemp.length;
                        idx_result++
                      ) {
                        if (
                          input_files[idx_input].name ===
                          resultRewardListTemp[idx_result].rewardName
                        ) {
                          ThisObj.RaidbossSettingdAddItem.rewardList.push(
                            resultRewardListTemp[idx_result]
                          );
                        }
                      }
                    }
                  }
                } else {
                  Message({
                    message: "Set Helper Parse Reward From File ",
                    type: "error",
                    duration: 5 * 1000,
                  });
                }
              })
              .catch((error) => {
                Message({
                  message:
                    "[AddItem_onImportRewardList]Set LeaderBoard All Settings 5: " +
                    error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          }
        } else {
          Message({
            message: "Set OnHandleRewardFileUpload code: " + code,
            type: "error",
            duration: 5 * 1000,
          });
        }
      });
    },
    //------------------------------------------------------------------------------------
    AddItem_handleRemoveQuickRewards(file, fileList) {
      this.additem_uploadFileQuickRewards = fileList;
    },
    //------------------------------------------------------------------------------------
    AddItem_onExportRewardList() {
      MessageBox.confirm(
        "Do you confirm export Reward List: " +
          this.RaidbossSettingdAddItem.name +
          "?",
        "Confirm export",
        {
          confirmButtonText: "Export",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        //RaidbossSettingdAddItem.rewardList = [
        //  {
        //            rewardName: ,
        //            rewards: [
        //	          {
        //		          id:,
        //              title: ,
        //              rankMin: ,
        //              rankMax: ,
        //              requiredPoint: ,
        //              gifts: ,
        //	           },...],
        //  },...]

        if (
          !this.RaidbossSettingdAddItem ||
          !this.RaidbossSettingdAddItem.rewardList
        ) {
          Message({
            message: "Export Reward List Failed: List is empty",
            type: "error",
            duration: 5 * 1000,
          });

          return;
        }

        //Message({message: "Export LeaderBoard Settings Data: " + JSON.stringify(uploadSettings), type: "success", duration: 5 * 1000});

        let export_filename =
          "export_raidboss_rewards_" +
          this.RaidbossSettingdAddItem.name +
          ".json";

        DownloadJS(
          JSON.stringify(this.RaidbossSettingdAddItem.rewardList),
          export_filename,
          "text/plain"
        );
      });
    },
    AddItem_onImportRewardList(file, fileList) {
      //Message({message: "AddItem_onImportRewardList content: " + file.raw, type: "error", duration: 5 * 1000});
      let objThis = this;

      try {
        if (file) {
          TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
            if (code === 0) {
              //Message({message: "AddItem_onImportRewardList content: " + data, type: "error", duration: 5 * 1000});

              let rewardList = JSON.parse(data);

              //Message({message: "AddItem_onImportRewardList content1: " + JSON.stringify(rewardList), type: "error", duration: 5 * 1000});

              objThis.RaidbossSettingdAddItem.rewardList = [];

              if (rewardList !== undefined) {
                //Message({message: "AddItem_onImportRewardList content2: " + JSON.stringify(rewardList), type: "error", duration: 5 * 1000});

                objThis.RaidbossSettingdAddItem.rewardList = rewardList;

                //Message({message: "AddItem_onImportRewardList content3: " + JSON.stringify(objThis.RaidbossSettingdAddItem.rewardList), type: "error", duration: 5 * 1000});
              }
            }
          });
        }
      } catch (err) {
        Message({
          message: "Exception Import File failed: " + err,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },
    AddItem_OnListChangeBossReward(data) {
      //Message({
      //message: "AddItem_OnListChangeBossReward: " + JSON.stringify(data),
      //type: "success",
      //duration: 5 * 1000,
      //});

      for (let i = 0; i < this.RaidbossSettingdAddItem.rewardList.length; i++) {
        if (
          this.RaidbossSettingdAddItem.rewardList[i].rewardName ===
          data.rewardName
        ) {
          this.RaidbossSettingdAddItem.rewardList[i].rewards = data.rewards;
          break;
        }
      }
    },
    AddItem_onGroupTypeChange(event) {
      Message({
        message:
          "Select Group Type: " +
          event +
          ", event list length: " +
          this.LeaderBoardItem_Groups_TypeList.length,
        type: "success",
        duration: 5 * 1000,
      });

      for (let i = 0; i < this.LeaderBoardItem_Groups_TypeList.length; i++) {
        if (this.LeaderBoardItem_Groups_TypeList[i].TypeName === event) {
          this.GroupAutoFill.LeaderBoardItem_Groups_Type = event;

          break;
        }
      }
    },
    AddItem_OnLeaderBoardItem_Groups_Add() {
      // full: <leaderboard name>_<os>_<type>_
      // display: _<os>_<type>_
      //
      this.GroupAutoFill.LeaderBoardItem_Groups_Name =
        "_" +
        this.GroupAutoFill.LeaderBoardItem_Groups_OS +
        "_" +
        this.GroupAutoFill.LeaderBoardItem_Groups_Type +
        "_";

      if (!this.GroupAutoFill.LeaderBoardItem_Groups_Name) {
        Message({
          message: "LeaderBoard Group Name is empty!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (
        !this.IsStringAlphaNumber(
          this.GroupAutoFill.LeaderBoardItem_Groups_Name
        )
      ) {
        Message({
          message:
            "LeaderBoard Group Name is only valid for characters alpha, number and '_'",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (
        !this.GroupAutoFill.LeaderBoardItem_Groups_RewardName ||
        this.GroupAutoFill.LeaderBoardItem_Groups_RewardName.length <= 0
      ) {
        Message({
          message: "Reward Name is empty!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      let isExist = false;

      for (
        let idx = 0;
        idx < this.RaidbossSettingdAddItem.grandBoss.groupReward.length;
        idx++
      ) {
        if (
          this.RaidbossSettingdAddItem.grandBoss.groupReward[idx].name ===
          this.GroupAutoFill.LeaderBoardItem_Groups_Name
        ) {
          isExist = true;
          break;
        }
      }

      if (isExist) {
        Message({
          message: "LeaderBoard Group Name is already existing!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      MessageBox.confirm(
        "Do you confirm Add group:" +
          this.GroupAutoFill.LeaderBoardItem_Groups_Name +
          ", count:" +
          this.GroupAutoFill.LeaderBoardItem_Groups_Count +
          ", size:" +
          this.GroupAutoFill.LeaderBoardItem_Groups_Size +
          ", payment size:" +
          this.GroupAutoFill.LeaderBoardItem_Groups_Payment_Size +
          ", none payment size: " +
          this.GroupAutoFill.LeaderBoardItem_Groups_None_Payment_Limit_Size_1,
        "Confirm edit",
        {
          confirmButtonText: "Add",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.RaidbossSettingdAddItem.grandBoss.groupReward.push({
          name: this.GroupAutoFill.LeaderBoardItem_Groups_Name,
          count: this.GroupAutoFill.LeaderBoardItem_Groups_Count,
          size: this.GroupAutoFill.LeaderBoardItem_Groups_Size,
          payment_size: this.GroupAutoFill.LeaderBoardItem_Groups_Payment_Size,
          none_payment_limit_size_1:
            this.GroupAutoFill.LeaderBoardItem_Groups_None_Payment_Limit_Size_1,
          rewardName: this.GroupAutoFill.LeaderBoardItem_Groups_RewardName,
          //rewards: this.GroupAutoFill.LeaderBoardItem_Groups_Rewards,
        });
      });
    },
    AddItem_OnLeaderBoardItem_Groups_Delete() {
      // full: <leaderboard name>_<os>_<type>_
      // display: _<os>_<type>_
      //
      this.GroupAutoFill.LeaderBoardItem_Groups_Name =
        "_" +
        this.GroupAutoFill.LeaderBoardItem_Groups_OS +
        "_" +
        this.GroupAutoFill.LeaderBoardItem_Groups_Type +
        "_";

      MessageBox.confirm(
        "Do you confirm Delete group:" +
          this.GroupAutoFill.LeaderBoardItem_Groups_Name,
        "Confirm edit",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        for (
          let idx = 0;
          idx < this.RaidbossSettingdAddItem.grandBoss.groupReward.length;
          idx++
        ) {
          if (
            this.RaidbossSettingdAddItem.grandBoss.groupReward[idx].name ===
            this.GroupAutoFill.LeaderBoardItem_Groups_Name
          ) {
            this.RaidbossSettingdAddItem.grandBoss.groupReward.splice(idx, 1);
            break;
          }
        }
      });
    },
    AddItem_OnGenerateEmptyListLittleBoss() {
      this.LittleBossListAutoFill.bossList = [];
      this.LittleBossListAutoFill.editMode = true;

      let raidbossName = this.RaidbossSettingdAddItem.name;

      let littleBossCaption = this.LittleBossAutoFill.bossCaption;

      let startDate = Number(this.RaidbossSettingdAddItem.eventInfo.startDate);

      let endDate =
        startDate +
        Number(
          Number(this.LittleBossAutoFill.activeIntervalInSec) -
            Number(this.LittleBossAutoFill.waitNextBossIntervalInSec)
        );

      let littleBossDateBegin = "1/1/2021 0:0:0";
      let littleBossDateEnd = "1/1/2021 0:0:0";
      let level = 0;
      let bossID = "bossID";

      let _startDate;
      let _endDate;

      //let debug = 0;
      const zeroPadString = (num, places) => String(num).padStart(places, "0");

      for (let idx = 0; idx < this.LittleBossAutoFill.count; idx++) {
        // example:
        // raidbossName = "raidboss"
        // littlebossName = raidboss_littleboss1

        let littleBossName = raidbossName + "_littleboss" + (idx + 1);

        _startDate = new Date(startDate * 1000);
        _endDate = new Date(endDate * 1000);

        littleBossDateBegin = _startDate;
        littleBossDateEnd = _endDate;

        level++;

        let levelDisplay = zeroPadString(level, 2);
        let littleBossCaptionDisplay = littleBossCaption.replace(
          "<tier>",
          levelDisplay
        );

        //----------------------------------------
        let itemBossInfos = [];
        let bossInfo = {};

        let rewardName = "";
        if (this.RaidbossSettingdAddItem.rewardList.length > 0) {
          rewardName = this.RaidbossSettingdAddItem.rewardList[0].rewardName;
        } else {
          Message({
            message: "The reward list is empty!! ",
            type: "error",
            duration: 5 * 1000,
          });

          return;
        }

        // loop all group to generate little boss for group
        for (
          let group_idx = 0;
          group_idx < this.RaidbossSettingdAddItem.grandBoss.groupReward.length;
          group_idx++
        ) {
          if (group_idx % 2 === 0) {
            bossID = level;
          } else {
            bossID = level + 100;
          }

          bossInfo = {
            tag: this.RaidbossSettingdAddItem.grandBoss.groupReward[group_idx]
              .name,
            bossCaption: littleBossCaptionDisplay, //littleBossCaption,
            maxHP: 123456 + level * 100000,
            level: level,
            bossID: bossID,
            rewardName: rewardName,
          };

          itemBossInfos.push(bossInfo);
        }

        let itemBoss = {
          name: littleBossName,
          startDate: littleBossDateBegin,
          endDate: littleBossDateEnd,
          info: itemBossInfos,
        };
        //----------------------------------------

        this.LittleBossListAutoFill.bossList.push(itemBoss);

        startDate += Number(this.LittleBossAutoFill.activeIntervalInSec);

        endDate =
          startDate +
          (Number(this.LittleBossAutoFill.activeIntervalInSec) -
            Number(this.LittleBossAutoFill.waitNextBossIntervalInSec));
      }
    },
    AddItem_onSyncBossListValuesFromConfigFile(file, fileList) {
      let ThisObj = this;

      if (file) {
        TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
          if (code === 0) {
            HelperParseJsonFromConfigFile(data)
              .then((response) => {
                if (response.data.code === 0) {
                  Message({
                    message: "Load boss configuration file succsessfully",
                    type: "success",
                    duration: 5 * 1000,
                  });

                  // Get Json object from configuration file

                  //ConfigRaidBoss
                  //ID	CampaignProgression	Name	Tier	BOSSImage	BOSSMaterial	MainHP	WeakpointHP	Attack1CancelHP	Attack2CancelHP	Attack3CancelHP	Attack1Damage	Attack2Damage	Attack3Damage	KnockDamageMultiplier	WeakpointDamageMultiplier	IdleTimeBetweenAttack	KnockingTime	BiasWeakpoint	BiasHand
                  //int|KEY	CampaignProgression	string	int	string	string	int	int	int	int	int	int	int	int	float	float	float	float	float	float
                  //
                  let jsonList = response.data.jsonList;

                  for (
                    let i = 0;
                    i < ThisObj.LittleBossListAutoFill.bossList.length;
                    i++
                  ) {
                    let itemBoss = ThisObj.LittleBossListAutoFill.bossList[i];

                    for (let j = 0; j < itemBoss.info.length; j++) {
                      let bossInfo = itemBoss.info[j];

                      if (bossInfo.bossID !== undefined) {
                        for (let idx = 0; idx < jsonList.length; idx++) {
                          if (
                            jsonList[idx].ID === undefined ||
                            jsonList[idx].MainHP === undefined
                          ) {
                            continue;
                          }

                          let segment = jsonList[idx].Segment;
                          let tier = jsonList[idx].Tier;

                          if (
                            segment !== undefined &&
                            segment !== null &&
                            tier !== undefined &&
                            tier !== null &&
                            segment === bossInfo.tag &&
                            Number(tier) === Number(bossInfo.level)
                          ) {
                            let bossID = Number(jsonList[idx].ID);
                            let maxHP = Number(jsonList[idx].MainHP);

                            let levelDisplay = String(bossInfo.level).padStart(
                              2,
                              "0"
                            );
                            let littleBossCaptionDisplay =
                              ThisObj.LittleBossAutoFill.bossCaption.replace(
                                "<tier>",
                                levelDisplay
                              );

                            bossInfo.bossID = bossID;
                            bossInfo.maxHP = maxHP;
                            bossInfo.bossCaption = littleBossCaptionDisplay;
                            break;
                          }
                        }
                      }
                    }
                  }
                } else {
                  Message({
                    message: "Set Helper Parse Boss From File ",
                    type: "error",
                    duration: 5 * 1000,
                  });
                }
              })
              .catch((error) => {
                //loadingInstance.close();
                Message({
                  message:
                    "[AddItem_onSyncBossListValuesFromConfigFile] Error: " +
                    error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          } else {
            Message({
              message:
                "AddItem_onSyncBossListValuesFromConfigFile code: " + code,
              type: "error",
              duration: 5 * 1000,
            });
          }
        });
      }
    },
    AddItem_onSyncBossListRewardFromConfigFile(file, fileList) {
      let ThisObj = this;

      if (file) {
        TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
          if (code === 0) {
            HelperParseJsonFromConfigFile(data)
              .then((response) => {
                if (response.data.code === 0) {
                  Message({
                    message: "Load BossID-RewardName map file succsessfully",
                    type: "success",
                    duration: 5 * 1000,
                  });

                  // Get Json object from configuration file

                  //ConfigTool_MapBossReward
                  //ID	RewardName
                  //int|KEY	string

                  let jsonList = response.data.jsonList;

                  for (
                    let i = 0;
                    i < ThisObj.LittleBossListAutoFill.bossList.length;
                    i++
                  ) {
                    let itemBoss = ThisObj.LittleBossListAutoFill.bossList[i];

                    for (let j = 0; j < itemBoss.info.length; j++) {
                      let bossInfo = itemBoss.info[j];

                      if (bossInfo.bossID !== undefined) {
                        for (let idx = 0; idx < jsonList.length; idx++) {
                          if (
                            jsonList[idx].ID === undefined ||
                            jsonList[idx].RewardName === undefined
                          ) {
                            continue;
                          }

                          let bossID = Number(jsonList[idx].ID);
                          let rewardName = jsonList[idx].RewardName;

                          if (bossInfo.bossID === bossID) {
                            if (
                              rewardName.length > 0 &&
                              ThisObj.AddItem_IsRewardNameExist(rewardName)
                            ) {
                              bossInfo.rewardName = rewardName;
                            }

                            break;
                          }
                        }
                      }
                    }
                  }
                } else {
                  Message({
                    message: "Set Helper Parse Boss From File ",
                    type: "error",
                    duration: 5 * 1000,
                  });
                }
              })
              .catch((error) => {
                //loadingInstance.close();
                Message({
                  message:
                    "[AddItem_onSyncBossListValuesFromConfigFile] Error: " +
                    error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          } else {
            Message({
              message:
                "AddItem_onSyncBossListValuesFromConfigFile code: " + code,
              type: "error",
              duration: 5 * 1000,
            });
          }
        });
      }
    },
    AddItem_IsRewardNameExist(rewardName) {
      let found = false;

      for (let i = 0; i < this.RaidbossSettingdAddItem.rewardList.length; i++) {
        if (
          this.RaidbossSettingdAddItem.rewardList[i].rewardName === rewardName
        ) {
          found = true;
          break;
        }
      }

      return found;
    },
    OnHandleRewardFileUpload(file, fileList) {
      //Message({message: "OnHandleRewardFileUpload content: " + file.raw, type: "error", duration: 5 * 1000});

      this.GroupAutoFill.LeaderBoardItem_Groups_Rewards = [];

      let leaderBoardItem_Rewards =
        this.GroupAutoFill.LeaderBoardItem_Groups_Rewards;

      if (file) {
        TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
          if (code === 0) {
            HelperParseRewardFromFile(data)
              .then((response) => {
                if (response.data.code === 0) {
                  Message({
                    message: "Import rewards from file succsessfully",
                    type: "success",
                    duration: 5 * 1000,
                  });

                  for (let idx = 0; idx < response.data.rewards.length; idx++) {
                    let itemGift = JSON.parse(response.data.rewards[idx].gifts);
                    if (!itemGift) {
                      Message({
                        message:
                          "Invalid Gift content: " +
                          response.data.rewards[idx].gifts,
                        type: "error",
                        duration: 5 * 1000,
                      });
                      return;
                    }

                    leaderBoardItem_Rewards.push({
                      id: Number(response.data.rewards[idx].id),
                      //eventId: response.data.rewards[idx].eventId,
                      title: response.data.rewards[idx].title,
                      rankMin: response.data.rewards[idx].rankMin,
                      rankMax: response.data.rewards[idx].rankMax,
                      requiredPoint: response.data.rewards[idx].requiredPoint,
                      gifts: response.data.rewards[idx].gifts,
                    });
                  }
                } else {
                  Message({
                    message: "Set Helper Parse Reward From File ",
                    type: "error",
                    duration: 5 * 1000,
                  });
                }
              })
              .catch((error) => {
                //loadingInstance.close();
                Message({
                  message:
                    "[HelperParseRewardFromFile]Set LeaderBoard All Settings 5: " +
                    error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          } else {
            Message({
              message: "Set OnHandleRewardFileUpload code: " + code,
              type: "error",
              duration: 5 * 1000,
            });
          }
        });
      }
    },

    CallbackChangeRewardData(data) {
      //Message({
      //            message: "CallbackChangeRewardData: " + JSON.stringify(data),
      //            type: "success",
      //            duration: 5 * 1000
      //          });
    },
    CallbackChangeGroupData(data) {
      /*
      Message({
        message: "CallbackChangeGroupData: " + JSON.stringify(data),
        type: "success",
        duration: 5 * 1000,
      });*/
    },
    Settings_CallbackChangeBossReward(data) {
      Message({
        message: "Settings_CallbackChangeBossReward: " + JSON.stringify(data),
        type: "success",
        duration: 5 * 1000,
      });
    },
    GetGrandBossLeaderBoardName(name) {
      return name + "_grandboss";
    },
    GetLittleBossLeaderBoardName(name) {
      return name + "_littleboss";
    },
    AddItem_OnAddBossSettings() {

      let isExistEventID = false;
      this.RaidBossSettingsList.forEach((configLDBSettings) => {
        let eventID = this.RaidbossSettingdAddItem.eventInfo.eventID;
        let eventInfo = configLDBSettings.data.eventInfo;
        if (
          eventInfo.eventID === eventID
        ) {
          Message({
            message: `LDB of Event ID: ${eventID} is existed, eventName = ${eventInfo.eventName}`,
            type: "error",
            duration: 5 * 1000,
          });

          isExistEventID = true;

          return;
        }
      });

      if (isExistEventID) {
        return;
      }

      if (!this.RaidbossSettingdAddItem.name) {
        Message({
          message: "LeaderBoard Name is empty!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (!this.IsStringAlphaNumber(this.RaidbossSettingdAddItem.name)) {
        Message({
          message:
            "LeaderBoard Name is only valid for characters alpha, number and '_'",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      this.RaidbossSettingdAddItem.name =
        this.RaidbossSettingdAddItem.name.toLowerCase();

      // boss active time using to loop show boss
      this.RaidbossSettingdAddItem.bossActiveTime = Number(
        this.LittleBossAutoFill.activeIntervalInSec
      );

      // waiting time for end boss
      this.RaidbossSettingdAddItem.bossWaitEndTime = Number(
        this.LittleBossAutoFill.waitNextBossIntervalInSec
      );

      this.RaidbossSettingdAddItem.isEnableRaidBossCheat = 0;

      this.RaidbossSettingdAddItem.grandBoss.leaderBoardName =
        this.GetGrandBossLeaderBoardName(this.RaidbossSettingdAddItem.name);

      let tmpNumber = Number(this.RaidbossSettingdAddItem.eventInfo.eventID);

      this.RaidbossSettingdAddItem.grandBoss.leaderBoardEventID =
        tmpNumber.toString();

      this.RaidbossSettingdAddItem.littleBoss.leaderBoardName =
        this.GetLittleBossLeaderBoardName(this.RaidbossSettingdAddItem.name);

      tmpNumber = Number(this.RaidbossSettingdAddItem.eventInfo.eventID) + 50;

      this.RaidbossSettingdAddItem.littleBoss.leaderBoardEventID =
        tmpNumber.toString();

      //this.RaidbossSettingdAddItem.littleBoss.bossList =
      //this.LittleBossListAutoFill.bossList;

      this.RaidbossSettingdAddItem.littleBoss.bossList = [];

      // Checking the boss settings is already existing
      let isExist = false;

      for (let idx = 0; idx < this.RaidBossSettingsList.length; idx++) {
        if (
          this.RaidbossSettingdAddItem.name ===
          this.RaidBossSettingsList[idx].data.name
        ) {
          isExist = true;
          break;
        }
      } //for

      if (isExist) {
        Message({
          message:
            "LeaderBoard Name is already existing! " +
            this.RaidbossSettingdAddItem.name +
            "," +
            JSON.stringify(this.RaidBossSettingsList),
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      /*
      Message({
        message:
          "AAAAAAAAAAAAAAAAAA! " +
          JSON.stringify(this.LittleBossListAutoFill.bossList),
        type: "error",
        duration: 5 * 1000,
      });
*/
      for (
        let idx = 0;
        idx < this.LittleBossListAutoFill.bossList.length;
        idx++
      ) {
        let bossListFill = this.LittleBossListAutoFill.bossList[idx];
        let infos = [];

        for (let idx2 = 0; idx2 < bossListFill.info.length; idx2++) {
          infos.push({
            tag: bossListFill.info[idx2].tag,
            //startDate: _startDateTimeStamp,
            //endDate: _endDateTimeStamp,
            bossCaption: bossListFill.info[idx2].bossCaption,
            maxHP: bossListFill.info[idx2].maxHP,
            level: bossListFill.info[idx2].level,
            bossID: bossListFill.info[idx2].bossID,
            rewardName: bossListFill.info[idx2].rewardName,
          });
        }

        let _startDate = new Date(bossListFill.startDate);
        let _startDateTimeStamp = _startDate.getTime() / 1000;

        let _endDate = new Date(bossListFill.endDate);
        let _endDateTimeStamp = _endDate.getTime() / 1000;

        this.RaidbossSettingdAddItem.littleBoss.bossList.push({
          name: bossListFill.name,
          startDate: _startDateTimeStamp,
          endDate: _endDateTimeStamp,
          info: infos,
        });
      }

      /*
      Message({
        message:
          "BBBBBBBBBBBBBBBBB! " +
          JSON.stringify(this.RaidbossSettingdAddItem.littleBoss),
        type: "error",
        duration: 5 * 1000,
      });
*/

      let id = 0;

      for (let idx = 0; idx < this.RaidBossSettingsList.length; idx++) {
        if (id < this.RaidBossSettingsList[idx].id) {
          id = this.RaidBossSettingsList[idx].id;
        }
      }

      id++;

      /*
      Message({
        message:
          "AddItem_OnAddBossSettings: " +
          JSON.stringify(this.RaidbossSettingdAddItem),
        type: "success",
        duration: 5 * 1000,
      });*/

      let eventInfo = this.RaidbossSettingdAddItem.eventInfo;

      let startDate = new Date(eventInfo.startDate * 1000);
      let endDate = new Date(eventInfo.endDate * 1000);

      let eventInfoDisplay = {
        eventID: eventInfo.eventID,
        eventType: eventInfo.eventType,
        eventName: eventInfo.eventName,
        startDate: startDate,
        endDate: endDate,
      };

      /*
      Message({
        message: "eventInfoDisplay: " + JSON.stringify(eventInfoDisplay),
        type: "success",
        duration: 5 * 1000,
      });
*/

      let dataItem = this.RaidbossSettingdAddItem;

      let addBossData = {
        id: id,
        data: dataItem,
        dataDisplay: {
          eventInfo: eventInfoDisplay,
        },
        editMode: false,
        updateMode: false,
      };

      this.RaidBossSettingsList.push(addBossData);
      //

      //------------------------------
      // upload data to remote server
      let uploadSettings = [];

      for (let idx = 0; idx < this.RaidBossSettingsList.length; idx++) {
        uploadSettings.push(this.RaidBossSettingsList[idx].data);
      }

      /*
      Message({
        message:
          "AddItem_OnAddBossSettings: uploadSettings: " +
          JSON.stringify(uploadSettings),
        type: "success",
        duration: 5 * 1000,
      });
      */

      HelperSetRaidBossAllSettings(uploadSettings)
        .then((response) => {
          this.loading = false;
          Message({
            message:
              "Set Raidboss All Settings Data: " +
              JSON.stringify(response.data),
            type: "success",
            duration: 5 * 1000,
          });

          //this.RaidbossSettingdAddItem.name = "";
          //this.leaderBoardItem.Rewards = [];
          //this.leaderBoardItem.EventInfo;

          //this.onGetRaidBossAllSetings();

          this.LeaderBoard_Apply_Settings_Status = APPLY_STATUS_ENABLE;
        })
        .catch((error) => {
          //loadingInstance.close();

          //this.LeaderBoardReset_Item();

          this.loading = false;
          Message({
            message: "Set LeaderBoard All Settings 4: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
      //------------------------------
    },
    handleClick(obj, e) {
      if (obj.label === "RaidBoss Settings") {
        // refresh black list
      } else if (obj.label === "RaidBoss Add") {
        // refresh black list
      }
    },
    cancelEdit(row) {
      row.updateMode = false;
      row.editMode = false;
    },
    confirmEdit(row) {
      MessageBox.confirm(
        "Do you confirm edit item: " + row.id + "?",
        "Confirm edit",
        {
          confirmButtonText: "Edit",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let uploadSettings = [];

        //convert startDate, endDate
        for (let idx = 0; idx < this.RaidBossSettingsList.length; idx++) {
          let timestampStartDate =
            this.RaidBossSettingsList[
              idx
            ].dataDisplay.eventInfo.startDate.getTime() / 1000;

          let timestampEndtDate =
            this.RaidBossSettingsList[
              idx
            ].dataDisplay.eventInfo.endDate.getTime() / 1000;

          this.RaidBossSettingsList[idx].data.eventInfo.startDate =
            timestampStartDate;

          this.RaidBossSettingsList[idx].data.eventInfo.endDate =
            timestampEndtDate;

          //------------
          // Update time little bosses
          let littleBossDisplay =
            this.RaidBossSettingsList[idx].dataDisplay.littleBoss;

          let bossListDisplay = littleBossDisplay.bossList;

          for (let idx_lb = 0; idx_lb < bossListDisplay.length; idx_lb++) {
            //{
            //  name: ,
            //    startDate: ,
            //    endDate: ,
            //    info: }
            let timestampStartDateLittleBoss =
              bossListDisplay[idx_lb].startDate.getTime() / 1000;

            let timestampEndDateLittleBoss =
              bossListDisplay[idx_lb].endDate.getTime() / 1000;

            this.RaidBossSettingsList[idx].data.littleBoss.bossList[
              idx_lb
            ].startDate = timestampStartDateLittleBoss;

            this.RaidBossSettingsList[idx].data.littleBoss.bossList[
              idx_lb
            ].endDate = timestampEndDateLittleBoss;
          }

          //------------

          uploadSettings.push(this.RaidBossSettingsList[idx].data);
        }

        HelperSetRaidBossAllSettings(uploadSettings)
          .then((response) => {
            this.LeaderBoard_Apply_Settings_Status = APPLY_STATUS_ENABLE;

            // get all leader board settings
            this.onGetRaidBossAllSetings();
          })
          .catch((error) => {
            Message({
              message: "Set LeaderBoard All Settings 2: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },
    onDelete(row) {
      MessageBox.confirm(
        "Do you confirm delete item: " + row.id + "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let uploadSettings = [];

        //Message({
        //  message:
        //    "Delete LeaderBoard this.RaidBossSettingsList: " +
        //    JSON.stringify(this.RaidBossSettingsList),
        //  type: "success",
        //  duration: 5 * 1000,
        //});

        for (let idx = 0; idx < this.RaidBossSettingsList.length; idx++) {
          let timestampStartDate =
            this.RaidBossSettingsList[
              idx
            ].dataDisplay.eventInfo.startDate.getTime() / 1000;

          let timestampEndtDate =
            this.RaidBossSettingsList[
              idx
            ].dataDisplay.eventInfo.endDate.getTime() / 1000;

          this.RaidBossSettingsList[idx].data.eventInfo.startDate =
            timestampStartDate;
          this.RaidBossSettingsList[idx].data.eventInfo.endDate =
            timestampEndtDate;

          if (this.RaidBossSettingsList[idx].id !== row.id) {
            uploadSettings.push(this.RaidBossSettingsList[idx].data);
          }
        }

        /*
        Message({
          message:
            "Delete LeaderBoard All Settings Data: " +
            JSON.stringify(uploadSettings),
          type: "success",
          duration: 5 * 1000,
        });
        */

        HelperSetRaidBossAllSettings(uploadSettings)
          .then((response) => {
            //Message({message: "Set LeaderBoard All Settings Data: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            this.LeaderBoard_Apply_Settings_Status = APPLY_STATUS_ENABLE;

            // get all leader board settings
            this.onGetRaidBossAllSetings();
          })
          .catch((error) => {
            Message({
              message: "Set LeaderBoard All Settings 1: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },
    onResetAll(name) {
      MessageBox.confirm(
        "Do you confirm reset all raidboss data: " + name + " ?",
        "Confirm edit",
        {
          confirmButtonText: "Reset All",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.Reset_All_Data_Status = APPLY_STATUS_PROCESSING;

        // start a timer to checking status processing next status ...
        if (this.Reset_All_Data_Status === APPLY_STATUS_PROCESSING) {
          this.Reset_All_Data_Time_Inc = 0;
          this.loading = true;
          setTimeout(() => this.onRecheckResetAllDataStatus(), 5000);
        }

        HelperResetAllRaidBossData(
          name,
          this.GetGrandBossLeaderBoardName(name),
          this.GetLittleBossLeaderBoardName(name)
        ).then((response) => {
          this.loading = false;
          this.Reset_All_Data_Status = APPLY_STATUS_DONE;

          if (response.data.code === 0) {
            Message({
              message: "Reset ALL DATA SUCCESS: " + name,
              type: "success",
              duration: 5 * 1000,
            });
          } else {
            Message({
              message: "Reset ALL DATA FAILED: " + name,
              type: "error",
              duration: 5 * 1000,
            });
          }
        });
      });
    },
    onSyncEventTimeToBossTime(name, eventID) {
      MessageBox.confirm(
        "Do you confirm synchronize time from event: " +
          eventID +
          " to raidboss data: " +
          name +
          " ?",
        "Confirm edit",
        {
          confirmButtonText: "Synchronize",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        // - get event data list all
        // - current event by event id
        // - set begin time/end time to raid boss event
        // - set begin time/end time to daily boss
        // - set modify status
        getEventDataFromFirebase()
          .then((response) => {
            if (response.events) {
              /*
            Message({
              message:
                "Get getEventDataFromFirebase " +
                JSON.stringify(response.events),
              type: "success",
              duration: 5 * 1000,
            });
            */

              //-------------------------------------
              let eventTmp = undefined;
              let raidBossSettingsItem = undefined;

              for (let i = 0; i < this.RaidBossSettingsList.length; i++) {
                if (
                  this.RaidBossSettingsList[i].data.eventInfo.eventID ===
                  eventID
                ) {
                  raidBossSettingsItem = this.RaidBossSettingsList[i];
                  break;
                }
              }

              for (let idx = 0; idx < response.events.length; idx++) {
                if (response.events[idx].event_id === eventID) {
                  eventTmp = response.events[idx];

                  /*
                Message({
                        message:
                          "Get getEventDataFromFirebase " +
                          JSON.stringify(eventTmp),
                        type: "success",
                        duration: 5 * 1000,
                      });
                */
                  break;
                }
              }

              if (eventTmp !== undefined && raidBossSettingsItem != null) {
                let startDateTimestamp = eventTmp.date_begin._seconds;
                let endDateTimestamp = eventTmp.date_end._seconds;

                // update the raidboss event info start time/endtime
                //
                raidBossSettingsItem.data.eventInfo.startDate =
                  startDateTimestamp;
                raidBossSettingsItem.data.eventInfo.endDate = endDateTimestamp;

                /*
              Message({
                        message:
                          "Get getEventDataFromFirebase " +
                          JSON.stringify(raidBossSettingsItem),
                        type: "success",
                        duration: 5 * 1000,
                      });
                      */

                // update the raidboss event info display start time/endtime
                //

                let startDateDisplay = new Date(
                  raidBossSettingsItem.data.eventInfo.startDate * 1000
                );
                let endDateDisplay = new Date(
                  raidBossSettingsItem.data.eventInfo.endDate * 1000
                );

                raidBossSettingsItem.dataDisplay.eventInfo.startDate =
                  startDateDisplay;
                raidBossSettingsItem.dataDisplay.eventInfo.endDate =
                  endDateDisplay;

                // update little boss start time/endtime
                //
                let startBossDateTimestamp = startDateTimestamp;
                let endBossDateTimestamp =
                  startBossDateTimestamp +
                  raidBossSettingsItem.data.bossActiveTime -
                  raidBossSettingsItem.data.bossWaitEndTime;

                for (
                  let idx = 0;
                  idx < raidBossSettingsItem.data.littleBoss.bossList.length;
                  idx++
                ) {
                  //----------------------------
                  raidBossSettingsItem.data.littleBoss.bossList[idx].startDate =
                    startBossDateTimestamp;
                  raidBossSettingsItem.data.littleBoss.bossList[idx].endDate =
                    endBossDateTimestamp;
                  //----------------------------

                  //----------------------------
                  startBossDateTimestamp +=
                    raidBossSettingsItem.data.bossActiveTime;
                  endBossDateTimestamp =
                    startBossDateTimestamp +
                    raidBossSettingsItem.data.bossActiveTime -
                    raidBossSettingsItem.data.bossWaitEndTime;
                  //----------------------------
                }

                // update little boss display start time/endtime
                //
                let littleBossList = [];
                let startDateBossDisplay;
                let endDateBossDisplay;

                for (
                  let idx_lb = 0;
                  idx_lb < raidBossSettingsItem.data.littleBoss.bossList.length;
                  idx_lb++
                ) {
                  let littleBossListItem =
                    raidBossSettingsItem.data.littleBoss.bossList[idx_lb];

                  startDateBossDisplay = new Date(
                    littleBossListItem.startDate * 1000
                  );
                  endDateBossDisplay = new Date(
                    littleBossListItem.endDate * 1000
                  );

                  littleBossList.push({
                    name: littleBossListItem.name,
                    startDate: startDateBossDisplay,
                    endDate: endDateBossDisplay,
                    info: littleBossListItem.info,
                  });
                }

                raidBossSettingsItem.dataDisplay.littleBoss.bossList =
                  littleBossList;
              }

              //------------------------------------------
              //convert startDate, endDate
              let uploadSettings = [];

              for (let idx = 0; idx < this.RaidBossSettingsList.length; idx++) {
                uploadSettings.push(this.RaidBossSettingsList[idx].data);
              }

              HelperSetRaidBossAllSettings(uploadSettings)
                .then((response) => {
                  this.LeaderBoard_Apply_Settings_Status = APPLY_STATUS_ENABLE;

                  // get all leader board settings
                  this.onGetRaidBossAllSetings();
                })
                .catch((error) => {
                  Message({
                    message: "Set LeaderBoard All Settings 3: " + error,
                    type: "error",
                    duration: 5 * 1000,
                  });
                });
              //------------------------------------------
            }

            //Message({message: "Get getEventDataFromFirebase -> LeaderBoardItem_EventIDList " + JSON.stringify(this.LeaderBoardItem_EventIDList), type: "success", duration: 5 * 1000});
          })
          .catch((error) => {
            Message({
              message: "Can't fetch config data list: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },
    OnMainCollapseLittleBoss(row) {
      row.IsCollapseLittleBossList = !row.IsCollapseLittleBossList;

      if (row.IsCollapseLittleBossList) {
        row.ButtonTitle_CollapseLittleBossList = "Click to Expand";
        row.ButtonIconName_CollapseLittleBossList = "el-icon-d-arrow-right";
      } else {
        row.ButtonTitle_CollapseLittleBossList = "Click to Collapse";
        row.ButtonIconName_CollapseLittleBossList = "el-icon-arrow-down";
      }
    },
    OnMainCollapseReward(row) {
      row.IsCollapseRewardList = !row.IsCollapseRewardList;

      if (row.IsCollapseRewardList) {
        row.ButtonTitle_CollapseRewardList = "Click to Expand";
        row.ButtonIconName_CollapseRewardList = "el-icon-d-arrow-right";
      } else {
        row.ButtonTitle_CollapseRewardList = "Click to Collapse";
        row.ButtonIconName_CollapseRewardList = "el-icon-arrow-down";
      }
    },
    onApplyLeaderBoardSettings() {
      //Message({message: "onApplyLeaderBoardSettings: ", type: "success", duration: 5 * 1000});

      this.loading = true;

      HelperApplyRaidBossAllSettingsPolling()
        .then((response) => {
          this.loading = false;

          if (response.data.code === 0) {
            if (response.data.message === "SUCCESS") {
              Message({
                message: "Apply LeaderBoard Settings: SUCCESS",
                type: "success",
                duration: 5 * 1000,
              });
            } else {
              Message({
                message: "Apply LeaderBoard Settings: " + response.data.message,
                type: "success",
                duration: 5 * 1000,
              });

              if (response.data.message === APPLY_STATUS_PROCESSING) {
                this.LeaderBoard_Apply_Settings_Status =
                  APPLY_STATUS_PROCESSING;
                this.LeaderBoard_Processing_Time_Inc = 0;
                this.loading = true;

                // start a timer to checking status processing next status ...
                //
                setTimeout(() => this.onRecheckApplySettingsStatus(), 5000);
              } else {
                this.loading = false;
              }
            }
          } else {
            if (response.data.code === 10) {
              Message({
                message:
                  response.data.message,
                type: "error",
                duration: 5 * 1000,
              });
            } else {
              Message({
                message:
                  "Apply LeaderBoard Settings: FAILED " + response.data.code,
                type: "error",
                duration: 5 * 1000,
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Apply LeaderBoard All Settings: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    onRecheckApplySettingsStatus() {
      HelperGetStatusPolling()
        .then((response) => {
          //Message({message: "Recheck Status Polling ..." + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          let code = response.data.code;
          let message = response.data.message;

          if (
            message === APPLY_STATUS_PROCESSING ||
            message === APPLY_STATUS_DONE ||
            message === APPLY_STATUS_ENABLE ||
            message === APPLY_STATUS_READY
          ) {
            if (message === APPLY_STATUS_READY) {
              message = APPLY_STATUS_DONE;
            }

            if (message === APPLY_STATUS_DONE) {
              Message({
                message: "Apply Settings ..." + message,
                type: "success",
                duration: 5 * 1000,
              });

              // Get Event Data List
              setTimeout(() => {
                // get all leader board settings
                this.onGetRaidBossAllSetings();

                //this.OnGetLeaderBoardRuntimeStatusList();
              }, 5000);
            }

            this.LeaderBoard_Apply_Settings_Status = message;

            // start a timer to checking status processing next status ...
            //
            if (message === APPLY_STATUS_PROCESSING) {
              this.loading = true;

              // If message is processing auto recheck status continuely
              setTimeout(() => this.onRecheckApplySettingsStatus(), 5000);
              this.LeaderBoard_Processing_Time_Inc += 5;
            } else {
              this.loading = false;
            }

            //Message({message: "Recheck Status Polling ... " + this.LeaderBoard_Apply_Settings_Status, type: "success", duration: 5 * 1000});
          } //other status
          else {
            // recheck polling status again
            // ...

            this.LeaderBoard_Apply_Settings_Status = APPLY_STATUS_READY;

            this.loading = false;
            Message({
              message: "Recheck Status ... Unknown status " + message,
              type: "error",
              duration: 60 * 1000,
            });
          }
        })
        .catch((error) => {
          // On error, auto recheck status later slowly
          setTimeout(() => this.onRecheckApplySettingsStatus(), 15000);
          this.LeaderBoard_Processing_Time_Inc += 15;

          Message({
            message: "Apply Settings Failed: " + error,
            type: "error",
            duration: 15 * 1000,
          });
          this.loading = false;
        });
    },
    onRecheckResetAllDataStatus() {
      // start a timer to checking status processing next status ...
      //
      if (this.Reset_All_Data_Status === APPLY_STATUS_PROCESSING) {
        this.loading = true;

        // If message is processing auto recheck status continuely
        setTimeout(() => this.onRecheckResetAllDataStatus(), 5000);
        this.Reset_All_Data_Time_Inc += 5;
      } else {
        this.loading = false;
      }
    },
    onSelectedBossNameChange(event) {
      Message({
        message: "Select boss name: " + event,
        type: "success",
        duration: 5 * 1000,
      });

      this.SelectedBossName = event;

      this.onGetBossEventData();
    },
    ResetBoss_onGroupChange(event) {
      //Message({
      //message: "Select group name: " + event,
      //type: "success",
      //duration: 5 * 1000,
      //});

      this.ResetBoss_GroupName = event;
    },
    onGetBossEventData() {
      if (!this.SelectedBossName) {
        return;
      }

      //-----------------------------------
      // generate reset boss info
      //
      this.ResetBoss_GroupName = "";
      this.Reset_listBossGroupInfos = [];

      let raidBossSetting = undefined;
      for (let i = 0; i < this.RaidBossSettingsList.length; i++) {
        if (this.RaidBossSettingsList[i].data.name === this.SelectedBossName) {
          raidBossSetting = this.RaidBossSettingsList[i].data;
          break;
        }
      }

      if (
        !raidBossSetting ||
        !raidBossSetting.grandBoss ||
        !raidBossSetting.grandBoss.groupReward
      ) {
        return;
      }

      let bossGroupSetting;
      for (let i = 0; i < raidBossSetting.grandBoss.groupReward.length; i++) {
        bossGroupSetting = raidBossSetting.grandBoss.groupReward[i];

        for (let j = 1; j <= bossGroupSetting.count; j++) {
          let groupName = bossGroupSetting.name + j;

          this.Reset_listBossGroupInfos.push({
            value: groupName,
            label: groupName,
          });
        }
      }

      if (this.Reset_listBossGroupInfos.length > 0) {
        this.ResetBoss_GroupName = this.Reset_listBossGroupInfos[0].value;
      }
      //-----------------------------------
    },
    ResetBoss_OnResetBoss() {
      let bossName = this.SelectedBossName;

      MessageBox.confirm(
        "Do you confirm reset boss: " +
          bossName +
          ", group: " +
          this.ResetBoss_GroupName +
          "?",
        "Confirm reset boss",
        {
          confirmButtonText: "Reset",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        HelperRaidBoss_ResetBossGroup(
          bossName,
          this.ResetBoss_GroupName,
          this.ResetBoss_HP
        )
          .then((response) => {
            if (response.data.code === 0) {
              Message({
                message:
                  "Reset boss: " +
                  bossName +
                  ", group: " +
                  this.ResetBoss_GroupName +
                  " SUCCESS",
                type: "success",
                duration: 5 * 1000,
              });

              this.ResetBoss_GroupInfo = JSON.stringify(response.data.info);
            } else {
              let message = response.data.message;

              Message({
                message:
                  "Reset boss: " +
                  bossName +
                  ", group: " +
                  this.ResetBoss_GroupName +
                  " FAILED " +
                  "(" +
                  message +
                  ")",
                type: "error",
                duration: 5 * 1000,
              });
            }
          })
          .catch((error) => {
            Message({
              message: "ResetBoss_OnResetBoss: " + error,
              type: "error",
              duration: 5 * 1000,
            });
            this.loading = false;
          });
      });
    },
    ResetBoss_OnGetStatus() {
      let bossName = this.SelectedBossName;

      HelperRaidBoss_GetStatusBossGroup(bossName, this.ResetBoss_GroupName)
        .then((response) => {
          if (response.data.code === 0) {
            //Message({
            //message: "Get boss: " + bossName +", group: " + this.ResetBoss_GroupName + " SUCCESS",
            //type: "success",
            //duration: 5 * 1000,
            //});

            this.ResetBoss_GroupInfo = JSON.stringify(response.data.info);
          } else {
            let message = response.data.message;

            Message({
              message:
                "Get boss: " +
                bossName +
                ", group: " +
                this.ResetBoss_GroupName +
                " FAILED " +
                "(" +
                message +
                ")",
              type: "error",
              duration: 5 * 1000,
            });

            this.ResetBoss_GroupInfo = "";
          }
        })
        .catch((error) => {
          Message({
            message: "ResetBoss_OnResetBoss: " + error,
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },
    IsStringAlphaNumber(input) {
      var letters = /^[0-9a-zA-Z_]+$/;
      if (input.match(letters)) {
        return true;
      } else {
        return false;
      }
    },
    Statistic_OnRefreshDailyList() {
      this.Statistic_getDailyListFile();
    },
    Statistic_getDailyListFile() {
      this.loading = true;
      this.statisticFileData = [];

      let promises = [];

      const getDailyStatisticFileList = async (bossName) => {
        let result = undefined;

        console.log("getDailyStatisticFileList: " + " bossName = " + bossName);

        await HelperRaidBoss_GetDailyStatisticFileList(bossName)
          .then((response) => {
            //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            let code = response.data.code;
            if (code === 0) {
              let retFileList = response.data.fileList;

              retFileList.sort(function (a, b) {
                if (a > b) {
                  return -1;
                }
                if (a < b) {
                  return 1;
                }
                return 0;
              });

              result = {
                result: "success",
                baseURL: response.data.baseURL,
                pathURL: response.data.pathURL,
                fileList: retFileList,
                error: "",
              };
            } else {
              result = {
                result: "failed",
                baseURL: "",
                pathURL: "",
                fileList: [],
                error: code,
              };
            }
          })
          .catch((error) => {
            result = {
              result: "failed",
              baseURL: "",
              pathURL: "",
              fileList: [],
              error: error,
            };
          });

        return result;
      };

      //Message({message: "Get Upload url " + urlCDN, type: "success", duration: 5 * 1000});

      promises.push(getDailyStatisticFileList(this.SelectedBossName));

      Promise.all(promises)
        .then((response) => {
          this.loading = false;

          var result = response[0];

          if (result.result == "success") {
            Message({
              message: "Get Statistic url " + result.pathURL,
              type: "success",
              duration: 5 * 1000,
            });

            let idx = 1;
            let baseURL = result.baseURL;
            let pathURL = result.pathURL;

            result.fileList.forEach((f) => {
              let link =
                baseURL + "/" + f + "?pathName=" + encodeURIComponent(pathURL);

              this.statisticFileData.push({
                id: idx,
                fileName: f,
                link: link,
              });

              idx++;
            });

            //Message({message: "Get Statistic file SUCCESS " + JSON.stringify(result), type: "success", duration: 5 * 1000});
          } else {
            Message({
              message: "Get Statistic file FAILED " + JSON.stringify(result),
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Get Statistic Files Failed! " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    Statistic_OnRefreshCheatLog() {
      this.loading = true;
      this.cheatLogFileData = [];

      let promises = [];

      const getCheatLogFileList = async (bossName) => {
        let result = undefined;

        console.log("getCheatLogFileList: " + " bossName = " + bossName);

        await HelperRaidBoss_GetCheatLogFileList(bossName)
          .then((response) => {
            //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            let code = response.data.code;
            if (code === 0) {
              let retFileList = response.data.fileList;

              retFileList.sort(function (a, b) {
                if (a > b) {
                  return -1;
                }
                if (a < b) {
                  return 1;
                }
                return 0;
              });

              result = {
                result: "success",
                baseURL: response.data.baseURL,
                pathURL: response.data.pathURL,
                fileList: retFileList,
                error: "",
              };
            } else {
              result = {
                result: "failed",
                baseURL: "",
                pathURL: "",
                fileList: [],
                error: code,
              };
            }
          })
          .catch((error) => {
            result = {
              result: "failed",
              baseURL: "",
              pathURL: "",
              fileList: [],
              error: error,
            };
          });

        return result;
      };

      //Message({message: "Get Upload url " + urlCDN, type: "success", duration: 5 * 1000});

      promises.push(getCheatLogFileList(this.SelectedBossName));

      Promise.all(promises)
        .then((response) => {
          this.loading = false;

          var result = response[0];

          if (result.result == "success") {
            //Message({message: "Get cheat log url " + result.pathURL, type: "success", duration: 5 * 1000});

            let idx = 1;
            let baseURL = result.baseURL;
            let pathURL = result.pathURL;

            result.fileList.forEach((f) => {
              let link =
                baseURL + "/" + f + "?pathName=" + encodeURIComponent(pathURL);

              this.cheatLogFileData.push({
                id: idx,
                fileName: f,
                link: link,
              });

              idx++;
            });

            //Message({message: "Get Cheat file SUCCESS " + JSON.stringify(result), type: "success", duration: 5 * 1000});
          } else {
            Message({
              message: "Get Cheat log file FAILED " + JSON.stringify(result),
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Get Cheat log Files Failed! " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    Statistic_SetAllBossGroupsStatus(arrData) {
      this.RaidBossAllGroupList = [];

      if (arrData !== null && arrData !== undefined) {
        let bossName = this.GetLittleBossLeaderBoardName(this.SelectedBossName);

        for (let idx = 0; idx < arrData.length; idx++) {
          if (arrData[idx].bossType !== "little") {
            continue;
          }

          let bossGroupName = "";
          if (arrData[idx].bossGroupName) {
            bossGroupName = arrData[idx].bossGroupName;

            if (bossGroupName.indexOf(bossName) === 0) {
              bossGroupName = bossGroupName.substring(bossName.length);
            }
          }

          let bossID = 0;
          if (arrData[idx].bossID) {
            bossID = Number(arrData[idx].bossID);
          }

          let hp = 0;
          if (arrData[idx].bossID) {
            hp = Number(arrData[idx].hp);
          }

          let maxHP = 0;
          if (arrData[idx].maxHP) {
            maxHP = Number(arrData[idx].maxHP);
          }

          let eliminateBossTime = 0;
          if (
            arrData[idx].statistic !== undefined &&
            arrData[idx].statistic.eliminateBossTime !== undefined
          ) {
            eliminateBossTime = Number(
              arrData[idx].statistic.eliminateBossTime
            );
          }

          let eliminateBossTimeDisplay = "";
          if (eliminateBossTime > 0) {
            eliminateBossTimeDisplay = new Date(eliminateBossTime * 1000);
          }

          let eliminateBossBy = "";
          if (
            arrData[idx].statistic !== undefined &&
            arrData[idx].statistic.eliminateBossBy !== undefined
          ) {
            eliminateBossBy = arrData[idx].statistic.eliminateBossBy;
          }

          let eliminateBossLastBattleHP = "";
          if (
            arrData[idx].statistic !== undefined &&
            arrData[idx].statistic.eliminateBossLastBattleHP !== undefined &&
            Number(arrData[idx].statistic.eliminateBossLastBattleHP) > 0
          ) {
            eliminateBossLastBattleHP =
              arrData[idx].statistic.eliminateBossLastBattleHP;
          }

          let giftStatus = "";
          if (
            arrData[idx].statistic !== undefined &&
            arrData[idx].statistic.giftStatus !== undefined
          ) {
            giftStatus = arrData[idx].statistic.giftStatus;
          }

          let bossGroupStateItem = {
            idx: idx + 1,
            bossGroupName: bossGroupName,
            bossID: bossID,
            hp: hp,
            maxHP: maxHP,
            eliminateBossTime: eliminateBossTime,
            eliminateBossTimeDisplay: eliminateBossTimeDisplay,
            eliminateBossBy: eliminateBossBy,
            eliminateBossLastBattleHP: eliminateBossLastBattleHP,
            giftStatus: giftStatus,
          };
          this.RaidBossAllGroupList.push(bossGroupStateItem);
        }

        this.RaidBossAllGroupList.sort(function (a, b) {
          return a.bossGroupName - b.bossGroupName;
        });
      }
    },
    Statistic_OnRefreshAllBossGroups() {
      let bossName = this.SelectedBossName;

      HelperRaidBoss_GetStatusAllBossGroup(bossName)
        .then((response) => {
          if (response.data.code === 0) {
            /*Message({
              message:
                "Get boss: " +
                bossName +
                " SUCCESS" +
                ",data: " +
                JSON.stringify(response.data.info),
              type: "success",
              duration: 5 * 1000,
            });*/

            Json2CSVConverter.csv2json(response.data.info, (err, arrData) => {
              if (err) {
                Message({
                  message:
                    "Convert Boss Group Status to Json failed" +
                    response.data.info,
                  type: "error",
                  duration: 5 * 1000,
                });
                return;
              }

              Message({
                message: "Get All Boss Group Status SUCCESS",
                type: "success",
                duration: 5 * 1000,
              });

              this.Statistic_SetAllBossGroupsStatus(arrData);
            });

            //this.ResetBoss_GroupInfo = JSON.stringify(response.data.info);
          } else {
            let message = response.data.message;

            Message({
              message:
                "Get boss: " + bossName + " FAILED " + "(" + message + ")",
              type: "error",
              duration: 5 * 1000,
            });

            //this.ResetBoss_GroupInfo = "";
          }
        })
        .catch((error) => {
          Message({
            message: "Statistic_OnRefreshAllBossGroups: " + error,
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.userprofile-container {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>
